import { call, takeLatest, put, select } from 'redux-saga/effects'
import api from 'services/api'

import {
  FAILED_CREATE_IMAGE,
  FAILED_IMAGE_UPLOAD,
  RECEIVE_CREATE_IMAGE,
  RECEIVE_IMAGE_UPLOAD,
  REQUEST_CREATE_IMAGE,
  REQUEST_IMAGE_UPLOAD,
} from 'redux/actions/image'

function readAsBinaryString(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (e) => resolve(btoa(e.target.result))
    reader.onerror = (err) => reject(err)
    reader.readAsBinaryString(blob)
  })
}

function* createImage(action) {
  try {
    const { blob, callbackAction, relationship } = action
    const { id } = yield call(api.post, '/image', {
      size: blob.size,
      name: blob.name,
      type: blob.type,
      relationship
    })

    yield put({ type: RECEIVE_CREATE_IMAGE })
    yield put({ type: REQUEST_IMAGE_UPLOAD, id, blob, callbackAction })
  } catch (error) {
    yield put({ type: FAILED_CREATE_IMAGE })
  }
}

function* uploadImage(action) {
  const { blob, callbackAction = null, id } = action
  const chunkSize = 1024 * 1024
  const totalSize = blob.size
  const chunks = Math.ceil(totalSize / chunkSize)
  const attempts = {}

  for (let i = 0; i < chunks; i++) {
    let start = i * chunkSize
    let end = start + chunkSize
    let sendBlob = blob.slice(start, end)
    // Set attempt
    attempts[i] = attempts[i] || 1

    try {
      let sendChunk = yield call(readAsBinaryString, sendBlob)

      yield call(api.put, `/image/${id}`, {
        data: sendChunk,
        chunk: i,
      })

      let progress = ((i + 1) / chunks) * 100

      yield put({ type: RECEIVE_IMAGE_UPLOAD, progress })

      // Dispatch on upload complete
      if (i === chunks - 1 && callbackAction) {
        yield put(callbackAction)
      }
    } catch (error) {
      // Max 5 retries
      if (++attempts[i] <= 5) {
        i--
        continue
      }

      yield put({ type: FAILED_IMAGE_UPLOAD })
      return
    }
  }
}

export default function* root() {
  yield takeLatest(REQUEST_CREATE_IMAGE, createImage)
  yield takeLatest(REQUEST_IMAGE_UPLOAD, uploadImage)
}
