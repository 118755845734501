export const CREATE_QUOTE_MUTATION = `
  mutation(
    $bookingId: ID
  ) {
    createQuote(
      bookingId: $bookingId
    ) {
      id
    }
  }
`

export const QUOTE_MUTATION = `
  mutation(
    $id: ID
    $booking: BookingInput
    $customer: CustomerInput
    $overrideExpirationDate: String
    $lineItems: [LineItemInput]
    $useOverrideExpirationDate: Boolean
  ) {
    quote(
      id: $id
      booking: $booking
      customer: $customer
      overrideExpirationDate: $overrideExpirationDate
      lineItems: $lineItems
      useOverrideExpirationDate: $useOverrideExpirationDate
    ) {
      id
      booking {
        id
        date
        groupSize
        remarks
        status {
          name
        }
        notes {
          id
          content
          user {
            name
          }
          createdAt
        }
        poNumber
      }
      company {
        region {
          name
        }
      }
      customer {
        id
        address {
          id
          city
          country
          housenumber
          streetname
          zipcode
        }
        businessName
        email
        financeEmail
        firstName
        isBusiness
        lastName
        phone
      }
      expirationDate
      lineItems {
        id
        description
        priceInCents
        quantity
        type
        vatRate
      }
      overrideExpirationDate
      remarks {
        body
        type
      }
      useOverrideExpirationDate
    }
  }
`
