import { call, takeLatest, put } from 'redux-saga/effects'
import graphql from 'services/graphql'

import {
  FAILED_GET_METRICS,
  RECEIVE_GET_METRICS,
  REQUEST_GET_METRICS,
} from 'redux/actions/dashboard'
import { METRICS_QUERY } from 'graphql/queries/dashboard'

function* getMetrics(action) {
  try {
    const data = yield call(graphql.query, METRICS_QUERY, {
      filters: action.filters?.map((filter) =>
        filter.field === 'aggregation_date'
          ? {
              ...filter,
              value: JSON.stringify(filter.value),
            }
          : filter
      ),
    })

    yield put({ type: RECEIVE_GET_METRICS, data: data.metrics[0] })
  } catch (error) {
    yield put({ type: FAILED_GET_METRICS, error })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_METRICS, getMetrics)
}
