export const FAILED_CREATE_INVOICE = 'FAILED_CREATE_INVOICE'
export const FAILED_DELETE_INVOICE = 'FAILED_DELETE_INVOICE'
export const FAILED_GET_INVOICE = 'FAILED_GET_INVOICE'
export const FAILED_GET_INVOICES = 'FAILED_GET_INVOICES'
export const FAILED_PERSIST_INVOICE = 'FAILED_PERSIST_INVOICE'
export const FAILED_SEND_INVOICE = 'FAILED_SEND_INVOICE'
export const RECEIVE_CREATE_INVOICE = 'RECEIVE_CREATE_INVOICE'
export const RECEIVE_DELETE_INVOICE = 'RECEIVE_DELETE_INVOICE'
export const RECEIVE_GET_INVOICE = 'RECEIVE_GET_INVOICE'
export const RECEIVE_GET_INVOICES = 'RECEIVE_GET_INVOICES'
export const RECEIVE_PERSIST_INVOICE = 'RECEIVE_PERSIST_INVOICE'
export const RECEIVE_SEND_INVOICE = 'RECEIVE_SEND_INVOICE'
export const REQUEST_CREATE_INVOICE = 'REQUEST_CREATE_INVOICE'
export const REQUEST_DELETE_INVOICE = 'REQUEST_DELETE_INVOICE'
export const REQUEST_GET_INVOICE = 'REQUEST_GET_INVOICE'
export const REQUEST_GET_INVOICES = 'REQUEST_GET_INVOICES'
export const REQUEST_PERSIST_INVOICE = 'REQUEST_PERSIST_INVOICE'
export const REQUEST_SEND_INVOICE = 'REQUEST_SEND_INVOICE'
