export const METRICS_QUERY = `
query (
  $filters: [FilterInput]
) {
  metrics (
    filters: $filters
  ) {
    averageResponseTimeInSeconds
    bookingsConfirmed
    bookingsQuoted
    bookingsRequested
    commission
    grossRevenue
  }
}
`
