export const REGION_QUERY = `
query (
  $id: ID!
) {
  region (
    id: $id
  ) {
    id
    name
  }
}
`

export const REGION_LIST_QUERY = `
query (
  $filters: [FilterInput]
) {
  regionList (
    filters: $filters
  ) {
    id
    name
  }
}
`

export const REGIONS_QUERY = `
query (
  $filters: [FilterInput]
  $pageNumber: Int
  $pageSize: Int
  $sorting: [SortingInput]
) {
  regions (
    filters: $filters
    pageNumber: $pageNumber
    pageSize: $pageSize
    sorting: $sorting
  ) {
    data {
      id
      name
      createdAt
      updatedAt
    }
    total
  }
}
`
