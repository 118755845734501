import { call, delay, takeLatest, put } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import api from 'services/api'
import graphql from 'services/graphql'
import {
  errorMessageFromGraphQL,
  successMessage,
  warningMessage,
  deletedMessage,
  savedMessage
} from 'config/toast'

import {
  FAILED_DELETE_USER,
  FAILED_GET_USER,
  FAILED_GET_USERS,
  FAILED_LOGIN_USER,
  FAILED_LOGOUT_USER,
  FAILED_PASSWORD_FORGOT,
  FAILED_PASSWORD_RESET,
  FAILED_PERSIST_USER,
  FAILED_USER_AUTH_STATUS,
  RECEIVE_DELETE_USER,
  RECEIVE_GET_USER,
  RECEIVE_GET_USERS,
  RECEIVE_LOGIN_USER,
  RECEIVE_LOGOUT_USER,
  RECEIVE_PASSWORD_FORGOT,
  RECEIVE_PASSWORD_RESET,
  RECEIVE_PERSIST_USER,
  RECEIVE_USER_AUTH_STATUS,
  REQUEST_DELETE_USER,
  REQUEST_GET_USER,
  REQUEST_GET_USERS,
  REQUEST_LOGIN_USER,
  REQUEST_LOGOUT_USER,
  REQUEST_PASSWORD_FORGOT,
  REQUEST_PASSWORD_RESET,
  REQUEST_PERSIST_USER,
  REQUEST_USER_AUTH_STATUS,
} from 'redux/actions/user'

import { NOTIFY } from 'redux/actions/notification'

import { USER_QUERY, USERS_QUERY } from 'graphql/queries/user'
import { USER_MUTATION } from 'graphql/mutations/user'

function* authStatus() {
  try {
    const { id, meta, role } = yield call(api.get, `/auth/status`);

    yield put({ type: RECEIVE_USER_AUTH_STATUS, id, meta, role })
  } catch (error) {
    yield put({ type: FAILED_USER_AUTH_STATUS })
  }
}

function* getUser(action) {
  try {
    const data = yield call(graphql.query, USER_QUERY, { id: action.id })

    yield put({ type: RECEIVE_GET_USER, data: data.user })
  } catch(error) {
    yield put({ type: FAILED_GET_USER, error })
  }
}

function* getUsers(action) {
  try {
    const data = yield call(graphql.query, USERS_QUERY, {
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      ...(action.filters ? { filters: action.filters } : {}),
      ...(action.sorting ? { sorting: action.sorting } : {}),
    })

    yield put({
      type: RECEIVE_GET_USERS,
      data: data.users.data,
      pageNumber: action.pageNumber,
      totalCount: data.users.total,
    })
  } catch(error) {
    yield put({ type: FAILED_GET_USERS, error })
  }
}

function* login(action) {
  try {
    yield call(api.get, `${process.env.RAZZLE_API_HOST}/sanctum/csrf-cookie`)
    yield call(api.post, '/login', action)

    // yield put({ type: RECEIVE_LOGIN_USER })
    window.location.href = '/';
  } catch(error) {
    yield put({ type: FAILED_LOGIN_USER, error })
  }
}

function* logout() {
  try {
    yield call(api.post, '/logout')

    yield put({ type: RECEIVE_LOGOUT_USER })
    window.location.href = '/login';
  } catch(error) {
    yield put({ type: FAILED_LOGOUT_USER, error })
  }
}

function* passwordForgot(action) {
  try {
    yield call(api.post, '/password/forgot', action)

    yield put({ type: RECEIVE_PASSWORD_FORGOT })
  } catch(error) {
    yield put({ type: FAILED_PASSWORD_FORGOT, error })
  }
}

function* passwordReset(action) {
  try {
    yield call(api.post, '/password/reset', action)

    window.location.href = '/login';
  } catch(error) {
    yield put({ type: FAILED_PASSWORD_RESET, error })
  }
}

function* persistUser(action) {
  try {
    const data = yield call(graphql.mutate, USER_MUTATION, action.data)

    yield put({ type: RECEIVE_GET_USER, data: data.user })

    const notification = successMessage(savedMessage('user'))
    yield put({ type: NOTIFY, notification })
  } catch(error) {
    yield put({ type: FAILED_GET_USER, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* deleteUser(action) {
  try {
    yield call(api.delete, `/user/${action.id}`)

    const notification = warningMessage(deletedMessage('user'))
    yield put({ type: NOTIFY, notification })
  } catch(error) {
    yield put({ type: FAILED_DELETE_USER, error })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_USER, getUser)
  yield takeLatest(REQUEST_GET_USERS, getUsers)
  yield takeLatest(REQUEST_LOGIN_USER, login)
  yield takeLatest(REQUEST_LOGOUT_USER, logout)
  yield takeLatest(REQUEST_PASSWORD_FORGOT, passwordForgot)
  yield takeLatest(REQUEST_PASSWORD_RESET, passwordReset)
  yield takeLatest(REQUEST_PERSIST_USER, persistUser)
  yield takeLatest(REQUEST_USER_AUTH_STATUS, authStatus)
  yield takeLatest(REQUEST_DELETE_USER, deleteUser)
}
