export const PRODUCT_MUTATION = `
mutation(
  $id: ID
  $applyCommission: Boolean
  $companyId: ID
  $description: I18nFieldInput!
  $forAmountOfPeople: Int
  $foodPricingType: String
  $i18nLanguage: String
  $imageId: ID
  $includedPricingType: String
  $name: I18nFieldInput!
  $packagePricePerPersonInCents: Int
  $pricePerHourInCents: Int
  $pricePerPersonInCents: Int
  $pricePerTripInCents: Int
  $priceInCents: Int
  $type: String
  $vatRate: String
)  @i18n(
  language: $i18nLanguage
) {
  product(
    id: $id
    applyCommission: $applyCommission
    companyId: $companyId
    description: $description
    forAmountOfPeople: $forAmountOfPeople
    foodPricingType: $foodPricingType
    imageId: $imageId
    includedPricingType: $includedPricingType
    name: $name
    packagePricePerPersonInCents: $packagePricePerPersonInCents
    pricePerHourInCents: $pricePerHourInCents
    pricePerPersonInCents: $pricePerPersonInCents
    pricePerTripInCents: $pricePerTripInCents
    priceInCents: $priceInCents
    type: $type
    vatRate: $vatRate
  ) {
    id
    applyCommission
    company {
      id
      name
    }
    description {
      value
    }
    forAmountOfPeople
    foodPricingType
    image {
      url
    }
    includedPricingType
    name {
      value
    }
    packagePricePerPersonInCents
    pricePerHourInCents
    pricePerPersonInCents
    priceInCents
    type
    vatRate
  }
}
`
