export const FAILED_DELETE_BOOKING = 'FAILED_DELETE_BOOKING'
export const FAILED_GET_BOOKING = 'FAILED_GET_BOOKING'
export const FAILED_GET_BOOKINGS = 'FAILED_GET_BOOKINGS'
export const FAILED_GET_NEW_BOOKINGS_COUNT = 'FAILED_GET_NEW_BOOKINGS_COUNT'
export const FAILED_PERSIST_BOOKING = 'FAILED_PERSIST_BOOKING'
export const RECEIVE_DELETE_BOOKING = 'RECEIVE_DELETE_BOOKING'
export const RECEIVE_GET_BOOKING = 'RECEIVE_GET_BOOKING'
export const RECEIVE_GET_BOOKINGS = 'RECEIVE_GET_BOOKINGS'
export const RECEIVE_GET_NEW_BOOKINGS_COUNT = 'RECEIVE_GET_NEW_BOOKINGS_COUNT'
export const RECEIVE_PERSIST_BOOKING = 'RECEIVE_PERSIST_BOOKING'
export const REQUEST_DELETE_BOOKING = 'REQUEST_DELETE_BOOKING'
export const REQUEST_GET_BOOKING = 'REQUEST_GET_BOOKING'
export const REQUEST_GET_BOOKINGS = 'REQUEST_GET_BOOKINGS'
export const REQUEST_GET_NEW_BOOKINGS_COUNT = 'REQUEST_GET_NEW_BOOKINGS_COUNT'
export const REQUEST_PERSIST_BOOKING = 'REQUEST_PERSIST_BOOKING'

export const REQUEST_GET_MESSAGES = 'REQUEST_GET_MESSAGES'
export const RECEIVE_GET_MESSAGES = 'RECEIVE_GET_MESSAGES'
export const FAILED_GET_MESSAGES = 'FAILED_GET_MESSAGES'

export const REQUEST_SEND_MESSAGE = 'REQUEST_SEND_MESSAGE'
