import { call, takeLatest, put } from 'redux-saga/effects'
import graphql from 'services/graphql'
import api from 'services/api'
import {
  errorMessageFromGraphQL,
  successMessage,
  warningMessage,
  deletedMessage,
  savedMessage,
} from 'config/toast'

import * as actions from 'redux/actions/surcharge'

import { NOTIFY } from 'redux/actions/notification'

import { SURCHARGE_QUERY, SURCHARGE_LIST_QUERY, SURCHARGES_QUERY } from 'graphql/queries/surcharge'
import { SURCHARGE_MUTATION } from 'graphql/mutations/surcharge'

function* getSurcharge(action) {
  try {
    const data = yield call(graphql.query, SURCHARGE_QUERY, { id: action.id })

    yield put({ type: actions.RECEIVE_GET_SURCHARGE, data: data.surcharge })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_SURCHARGE, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* getSurchargeList(action) {
  try {
    const data = yield call(graphql.query, SURCHARGE_LIST_QUERY, {
      ...(action.filters ? { filters: action.filters } : {}),
    })

    yield put({
      type: actions.RECEIVE_GET_SURCHARGES,
      data: data.surchargeList,
      pageNumber: 0,
      totalCount: 0,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_SURCHARGES, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* getSurcharges(action) {
  try {
    const data = yield call(graphql.query, SURCHARGES_QUERY, {
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      ...(action.filters ? { filters: action.filters } : {}),
      ...(action.sorting ? { sorting: action.sorting } : {}),
    })

    yield put({
      type: actions.RECEIVE_GET_SURCHARGES,
      data: data.surcharges.data,
      pageNumber: action.pageNumber,
      totalCount: data.surcharges.total,
    })
  } catch (error) {
    yield put({ type: actions.FAILED_GET_SURCHARGES, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* persistSurcharge(action) {
  try {
    delete action.data.__typename

    const data = yield call(graphql.mutate, SURCHARGE_MUTATION, action.data)

    yield put({
      type: actions.RECEIVE_PERSIST_SURCHARGE,
      data: data.surcharge,
    })

    const notification = successMessage(savedMessage('surcharge'))
    yield put({ type: NOTIFY, notification })
  } catch (error) {
    yield put({ type: actions.FAILED_PERSIST_SURCHARGE, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* deleteSurcharge(action) {
  try {
    yield call(api.delete, `/surcharge/${action.id}`)

    const notification = warningMessage(deletedMessage('surcharge'))
    yield put({ type: NOTIFY, notification })
  } catch (error) {
    yield put({ type: actions.FAILED_DELETE_SURCHARGE, error })
  }
}

export default function* root() {
  yield takeLatest(actions.REQUEST_GET_SURCHARGE, getSurcharge)
  yield takeLatest(actions.REQUEST_GET_SURCHARGE_LIST, getSurchargeList)
  yield takeLatest(actions.REQUEST_GET_SURCHARGES, getSurcharges)
  yield takeLatest(actions.REQUEST_PERSIST_SURCHARGE, persistSurcharge)
  yield takeLatest(actions.REQUEST_DELETE_SURCHARGE, deleteSurcharge)
}
