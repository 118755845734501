import {call, takeLatest, put} from 'redux-saga/effects'
import { push } from 'react-router-redux'
import graphql from 'services/graphql'

import * as reportActions from 'redux/actions/report'
import * as reportQueries from 'graphql/queries/report'

function* getReports(action) {
  try {
    const data = yield call(graphql.query, reportQueries.REPORTS_QUERY, {
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      ...(action.filters ? { filters: action.filters } : {}),
      ...(action.sorting ? { sorting: action.sorting } : {}),
    })

    yield put({
      type: reportActions.RECEIVE_GET_REPORTS,
      data: data.reports.data,
      pageNumber: action.pageNumber,
      totalCount: data.reports.total,
    })
  } catch(error) {
    yield put({ type: reportActions.FAILED_GET_REPORTS, error })
  }
}

export default function* root() {
  yield takeLatest(reportActions.REQUEST_GET_REPORTS, getReports)
}
