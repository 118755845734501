import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import createSagaMiddleware,  { END } from 'redux-saga'

// Import helpers
import { isServer } from 'config/helpers'
import history from 'config/history'

// Import Combined Reducers "rootReducer"
import rootReducer from "redux/reducers"

// Import rootSaga
import rootSaga from "redux/sagas"
const sagaMiddleware = createSagaMiddleware()

// Add Middleware
const middlewares = [
  sagaMiddleware,
  routerMiddleware(history) // add browser history
]

// Enable Thunk console logging in dev mode
if (!isServer && process.env.NODE_ENV === 'development') {
  const loggerMiddleware = createLogger({
    collapsed: true
  })

 middlewares.push(loggerMiddleware)
}

// Do we have preloaded state available? Great, save it.
const initialState = !isServer ? window.__PRELOADED_STATE__ : {};

// Delete it once we have it stored in a variable
if (!isServer) {
  delete window.__PRELOADED_STATE__;
}

// Create Store
const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middlewares)
)

// Enable Webpack hot module replacement for reducers
if (module.hot) {
  module.hot.accept('redux/reducers', () => {
    const nextRootReducer = require('redux/reducers').default;
    store.replaceReducer(nextRootReducer);
  });
}

store.runSaga = sagaMiddleware.run

// Make sure to run rootSaga
if (!isServer) {
  store.close = () => store.dispatch(END)
  store.runSaga(rootSaga)
} else {
  store.runSaga(rootSaga)
    .toPromise().then(() => {
      console.log('🔥 Sagas configured')
    }).catch((e) => {
      console.log('💥 Sagas error', e.message)
    })
}

export { createStore } from 'redux'

export default store
