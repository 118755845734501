import {call, takeLatest, put} from 'redux-saga/effects'
import graphql from 'services/graphql'
import api from 'services/api'
import {
  errorMessageFromGraphQL,
  successMessage,
  warningMessage,
  deletedMessage,
  savedMessage
} from 'config/toast'

import {
  FAILED_GET_BOAT,
  FAILED_GET_BOATS,
  FAILED_PERSIST_BOAT,
  FAILED_DELETE_BOAT,
  RECEIVE_GET_BOAT,
  RECEIVE_GET_BOATS,
  RECEIVE_PERSIST_BOAT,
  RECEIVE_DELETE_BOAT,
  REQUEST_GET_BOAT,
  REQUEST_GET_BOAT_LIST,
  REQUEST_GET_BOATS,
  REQUEST_PERSIST_BOAT,
  REQUEST_DELETE_BOAT,
} from 'redux/actions/boat'

import { NOTIFY } from 'redux/actions/notification'

import {BOAT_QUERY, BOAT_LIST_QUERY, BOATS_QUERY} from 'graphql/queries/boat'
import {BOAT_MUTATION} from 'graphql/mutations/boat'

function* getBoat(action) {
  try {
    const data = yield call(graphql.query, BOAT_QUERY, { id: action.id })

    yield put({ type: RECEIVE_GET_BOAT, data: data.boat })
  } catch(error) {
    yield put({ type: FAILED_GET_BOAT, error })
    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* getBoatList(action) {
  try {
    const data = yield call(graphql.query, BOAT_LIST_QUERY, {
      ...(action.filters ? { filters: action.filters } : {}),
    })

    yield put({
      type: RECEIVE_GET_BOATS,
      data: data.boatList,
      pageNumber: 0,
      totalCount: 0,
    })
  } catch(error) {
    yield put({ type: FAILED_GET_BOATS, error })
    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* getBoats(action) {
  try {
    const data = yield call(graphql.query, BOATS_QUERY, {
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      ...(action.filters ? { filters: action.filters } : {}),
      ...(action.sorting ? { sorting: action.sorting } : {}),
    })

    yield put({
      type: RECEIVE_GET_BOATS,
      data: data.boats.data,
      pageNumber: action.pageNumber,
      totalCount: data.boats.total,
    })
  } catch(error) {
    yield put({ type: FAILED_GET_BOATS, error })
    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* persistBoat(action) {
  try {
    delete action.data.__typename

    // Remove ids from new trip durations
    if (action?.data?.tripDurations?.length > 0) {
      action.data.tripDurations = action.data.tripDurations
        .map(({id, ...duration}) => ({
          ...(id.startsWith('new-') ? {} : {id}),
          ...duration
        }))
    }

    const data = yield call(graphql.mutate, BOAT_MUTATION, action.data)

    yield put({
      type: RECEIVE_PERSIST_BOAT,
      data: data.boat,
    })

    const notification = successMessage(savedMessage('boat'))
    yield put({ type: NOTIFY, notification })
  } catch(error) {
    yield put({ type: FAILED_PERSIST_BOAT, error })
    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* deleteBoat(action) {
  try {
    yield call(api.delete, `/boat/${action.id}`)

    yield put({ type: RECEIVE_DELETE_BOAT })

    const notification = warningMessage(deletedMessage('boat'))
    yield put({ type: NOTIFY, notification })
  } catch(error) {
    yield put({ type: FAILED_DELETE_BOAT, error })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_BOAT, getBoat)
  yield takeLatest(REQUEST_GET_BOAT_LIST, getBoatList)
  yield takeLatest(REQUEST_GET_BOATS, getBoats)
  yield takeLatest(REQUEST_PERSIST_BOAT, persistBoat)
  yield takeLatest(REQUEST_DELETE_BOAT, deleteBoat)
}
