export const RECEIVE_DELETE_SURCHARGE = 'RECEIVE_DELETE_SURCHARGE'
export const RECEIVE_GET_SURCHARGE = 'RECEIVE_GET_SURCHARGE'
export const RECEIVE_GET_SURCHARGE_LIST = 'RECEIVE_GET_SURCHARGE_LIST'
export const RECEIVE_GET_SURCHARGES = 'RECEIVE_GET_SURCHARGES'
export const RECEIVE_PERSIST_SURCHARGE = 'RECEIVE_PERSIST_SURCHARGE'
export const REQUEST_DELETE_SURCHARGE = 'REQUEST_DELETE_SURCHARGE'
export const REQUEST_GET_SURCHARGE = 'REQUEST_GET_SURCHARGE'
export const REQUEST_GET_SURCHARGE_LIST = 'REQUEST_GET_SURCHARGE_LIST'
export const REQUEST_GET_SURCHARGES = 'REQUEST_GET_SURCHARGES'
export const REQUEST_PERSIST_SURCHARGE = 'REQUEST_PERSIST_SURCHARGE'
export const FAILED_DELETE_SURCHARGE = 'FAILED_DELETE_SURCHARGE'
export const FAILED_GET_SURCHARGE = 'FAILED_GET_SURCHARGE'
export const FAILED_GET_SURCHARGE_LIST = 'FAILED_GET_SURCHARGE_LIST'
export const FAILED_GET_SURCHARGES = 'FAILED_GET_SURCHARGES'
export const FAILED_PERSIST_SURCHARGE = 'FAILED_PERSIST_SURCHARGE'
