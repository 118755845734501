export const ROLE_ADMINISTRATOR = 'administrator'

export const ROLE_COMPANY_ADMIN = 'company-admin'

export const ROLE_COMPANY_USER = 'company-user'

export const supportedRoles = [
  ROLE_ADMINISTRATOR,
  ROLE_COMPANY_ADMIN,
  ROLE_COMPANY_USER,
]
