import { combineReducers } from 'redux'
import { isServer } from 'config/helpers'
import { routerReducer as router } from 'react-router-redux'

import boat from './boat'
import booking from './booking'
import company from './company'
import customer from './customer'
import dashboard from './dashboard'
import experiment from './experiment'
import invoice from './invoice'
import packageReducer from './package' // package is a reserved word...
import product from './product'
import quote from './quote'
import region from './region'
import report from './report'
import role from './role'
import surcharge from './surcharge'
import user from './user'
import vatRate from './vatRate'

const appReducer = combineReducers({
  preloaded: () => !isServer,
  boat,
  booking,
  company,
  customer,
  dashboard,
  experiment,
  invoice,
  package: packageReducer,
  product,
  quote,
  region,
  report,
  role,
  router,
  surcharge,
  user,
  vatRate,
})

export default function (state = {}, action = {}) {
  return appReducer(state, action)
}
