export const VAT_RATE_QUERY = `
query ($id: ID!) {
  vatRates(id: $id) {
    id
    percentage
    type
    createdAt
    updatedAt
  }
}
`

export const VAT_RATES_QUERY = `
query {
  vatRates {
    id
    percentage
    type
    createdAt
    updatedAt
  }
}
`
