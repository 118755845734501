export const BOOKING_MUTATION = `
mutation($id: ID!, $groupSize: Int!) {
  booking(
    id: $id
    groupSize: $groupSize    
  ) {
    id
    groupSize
  }
}
`

export const CONFIRMED_BOOKING_CHANGE_MUTATION = `
  mutation(
    $id: ID
    $booking: BookingInput
    $lineItems: [LineItemInput]
  ) {
    confirmedBookingChange(
      id: $id
      booking: $booking
      lineItems: $lineItems
    ) {
      id
    }
  }
`
