export const RECEIVE_DELETE_VAT_RATE = 'RECEIVE_DELETE_VAT_RATE'
export const RECEIVE_GET_VAT_RATE = 'RECEIVE_GET_VAT_RATE'
export const RECEIVE_GET_VAT_RATES = 'RECEIVE_GET_VAT_RATES'
export const RECEIVE_PERSIST_VAT_RATE = 'RECEIVE_PERSIST_VAT_RATE'
export const REQUEST_DELETE_VAT_RATE = 'REQUEST_DELETE_VAT_RATE'
export const REQUEST_GET_VAT_RATE = 'REQUEST_GET_VAT_RATE'
export const REQUEST_GET_VAT_RATES = 'REQUEST_GET_VAT_RATES'
export const REQUEST_PERSIST_VAT_RATE = 'REQUEST_PERSIST_VAT_RATE'
export const FAILED_DELETE_VAT_RATE = 'FAILED_DELETE_VAT_RATE'
export const FAILED_GET_VAT_RATE = 'FAILED_GET_VAT_RATE'
export const FAILED_GET_VAT_RATES = 'FAILED_GET_VAT_RATES'
export const FAILED_PERSIST_VAT_RATE = 'FAILED_PERSIST_VAT_RATE'
