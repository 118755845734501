export const RECEIVE_DELETE_BOAT = 'RECEIVE_DELETE_BOAT'
export const RECEIVE_GET_BOAT = 'RECEIVE_GET_BOAT'
export const RECEIVE_GET_BOAT_LIST = 'RECEIVE_GET_BOAT_LIST'
export const RECEIVE_GET_BOATS = 'RECEIVE_GET_BOATS'
export const RECEIVE_PERSIST_BOAT = 'RECEIVE_PERSIST_BOAT'
export const REQUEST_DELETE_BOAT = 'REQUEST_DELETE_BOAT'
export const REQUEST_GET_BOAT = 'REQUEST_GET_BOAT'
export const REQUEST_GET_BOAT_LIST = 'REQUEST_GET_BOAT_LIST'
export const REQUEST_GET_BOATS = 'REQUEST_GET_BOATS'
export const REQUEST_PERSIST_BOAT = 'REQUEST_PERSIST_BOAT'
export const FAILED_DELETE_BOAT = 'FAILED_DELETE_BOAT'
export const FAILED_GET_BOAT = 'FAILED_GET_BOAT'
export const FAILED_GET_BOAT_LIST = 'FAILED_GET_BOAT_LIST'
export const FAILED_GET_BOATS = 'FAILED_GET_BOATS'
export const FAILED_PERSIST_BOAT = 'FAILED_PERSIST_BOAT'
