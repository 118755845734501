export const SURCHARGE_MUTATION = `
mutation(
  $id: ID
  $companyId: ID
  $name: String!
  $surchargeInCents: Int!
  $surchargePricingType: String!
) {
  surcharge(
    id: $id
    companyId: $companyId
    name: $name
    surchargeInCents: $surchargeInCents
    surchargePricingType: $surchargePricingType
  ) {
    id
    company {
      id
      name
    }
    name
    surchargeInCents
    surchargePricingType
  }
}
`
