import {call, takeLatest, put} from 'redux-saga/effects'
import graphql from 'services/graphql'
import api from 'services/api'

import {
  FAILED_GET_VAT_RATE,
  FAILED_GET_VAT_RATES,
  FAILED_PERSIST_VAT_RATE,
  FAILED_DELETE_VAT_RATE,
  RECEIVE_GET_VAT_RATE,
  RECEIVE_GET_VAT_RATES,
  RECEIVE_PERSIST_VAT_RATE,
  RECEIVE_DELETE_VAT_RATE,
  REQUEST_GET_VAT_RATE,
  REQUEST_GET_VAT_RATES,
  REQUEST_PERSIST_VAT_RATE,
  REQUEST_DELETE_VAT_RATE,
} from 'redux/actions/vatRate'

import {VAT_RATE_QUERY, VAT_RATES_QUERY} from 'graphql/queries/vatRate'
import {VAT_RATE_MUTATION} from 'graphql/mutations/vatRate'

function* getVatRate(action) {
  try {
    const data = yield call(graphql.query, VAT_RATE_QUERY, { id: action.id })

    yield put({ type: RECEIVE_GET_VAT_RATE, data: data.vatRates[0] })
  } catch(error) {
    yield put({ type: FAILED_GET_VAT_RATE, error })
  }
}

function* getVatRates(action) {
  try {
    const data = yield call(graphql.query, VAT_RATES_QUERY)

    yield put({ type: RECEIVE_GET_VAT_RATES, data: data.vatRates })
  } catch(error) {
    yield put({ type: FAILED_GET_VAT_RATES, error })
  }
}

function* persistVatRate(action) {
  try {
    delete action.data.__typename

    const data = yield call(graphql.mutate, VAT_RATE_MUTATION, action.data)

    yield put({
      type: RECEIVE_PERSIST_VAT_RATE,
      data: data.vatRate,
    })
  } catch(error) {
    yield put({ type: FAILED_PERSIST_VAT_RATE, error })
  }
}

function* deleteVatRate(action) {
  try {
    yield call(api.delete, `/vatRate/${action.id}`)
  } catch(error) {
    yield put({ type: FAILED_DELETE_VAT_RATE, error })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_VAT_RATE, getVatRate)
  yield takeLatest(REQUEST_GET_VAT_RATES, getVatRates)
  yield takeLatest(REQUEST_PERSIST_VAT_RATE, persistVatRate)
  yield takeLatest(REQUEST_DELETE_VAT_RATE, deleteVatRate)
}
