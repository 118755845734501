import { call, takeLatest, put } from 'redux-saga/effects'
import graphql from 'services/graphql'

import {
  FAILED_GET_INVOICE,
  FAILED_GET_INVOICES,
  RECEIVE_GET_INVOICE,
  RECEIVE_GET_INVOICES,
  REQUEST_GET_INVOICE,
  REQUEST_GET_INVOICES,
  FAILED_PERSIST_INVOICE,
  RECEIVE_PERSIST_INVOICE,
  REQUEST_PERSIST_INVOICE,
} from 'redux/actions/invoice'
import { NOTIFY } from 'redux/actions/notification'
import { INVOICE_QUERY, INVOICES_QUERY } from 'graphql/queries/invoice'
import { INVOICE_MUTATION } from 'graphql/mutations/invoice'
import { errorMessageFromGraphQL, savedMessage, successMessage } from 'common/config/toast'
import { CONFIRMED_BOOKING_CHANGE_MUTATION } from 'common/graphql/mutations/booking'

function* getInvoice(action) {
  try {
    const data = yield call(graphql.query, INVOICE_QUERY, { id: action.id })

    yield put({ type: RECEIVE_GET_INVOICE, data: data.invoice })
  } catch (error) {
    yield put({ type: FAILED_GET_INVOICE, error })
  }
}

function* getInvoices(action) {
  try {
    const data = yield call(graphql.query, INVOICES_QUERY, {
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      ...(action.filters ? { filters: action.filters } : {}),
      ...(action.sorting ? { sorting: action.sorting } : {}),
    })

    yield put({
      type: RECEIVE_GET_INVOICES,
      data: data.invoices.data,
      pageNumber: action.pageNumber,
      totalCount: data.invoices.total,
    })
  } catch (error) {
    yield put({ type: FAILED_GET_INVOICES, error })
  }
}

function* persistInvoice(action) {
  try {
    delete action.data.__typename

    const sanitisedData = JSON.parse(JSON.stringify(action.data))
    delete sanitisedData.booking.notes

    // Remove ids from new line items
    if (sanitisedData?.lineItems?.length > 0) {
      sanitisedData.lineItems = sanitisedData.lineItems.map(({ id, ...lineItem }) => ({
        ...(id.startsWith('new-') ? {} : { id }),
        ...lineItem,
      }))
    }

    const data = yield call(graphql.mutate, CONFIRMED_BOOKING_CHANGE_MUTATION, {
      id: sanitisedData.id,
      lineItems: sanitisedData.lineItems,
      booking: sanitisedData.booking,
    })

    yield put({ type: REQUEST_GET_INVOICE, id: sanitisedData.id })

    const notification = successMessage(savedMessage('tripRequest'))
    yield put({ type: NOTIFY, notification })
  } catch (error) {
    yield put({ type: FAILED_PERSIST_INVOICE, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_INVOICE, getInvoice)
  yield takeLatest(REQUEST_GET_INVOICES, getInvoices)
  yield takeLatest(REQUEST_PERSIST_INVOICE, persistInvoice)
}
