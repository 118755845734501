import {call, takeLatest, put} from 'redux-saga/effects'
import graphql from 'services/graphql'
import api from 'services/api'
import {
  errorMessageFromGraphQL,
  successMessage,
  warningMessage,
  deletedMessage,
  savedMessage
} from 'config/toast'

import {
  FAILED_GET_REGION,
  FAILED_GET_REGIONS,
  FAILED_PERSIST_REGION,
  FAILED_DELETE_REGION,
  RECEIVE_GET_REGION,
  RECEIVE_GET_REGIONS,
  RECEIVE_PERSIST_REGION,
  RECEIVE_DELETE_REGION,
  REQUEST_GET_REGION,
  REQUEST_GET_REGION_LIST,
  REQUEST_GET_REGIONS,
  REQUEST_PERSIST_REGION,
  REQUEST_DELETE_REGION,
} from 'redux/actions/region'

import { NOTIFY } from 'redux/actions/notification'

import {REGION_QUERY, REGION_LIST_QUERY, REGIONS_QUERY} from 'graphql/queries/region'
import {REGION_MUTATION} from 'graphql/mutations/region'

function* getRegion(action) {
  try {
    const data = yield call(graphql.query, REGION_QUERY, { id: action.id })

    yield put({ type: RECEIVE_GET_REGION, data: data.region })
  } catch(error) {
    yield put({ type: FAILED_GET_REGION, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* getRegionList(action) {
  try {
    const data = yield call(graphql.query, REGION_LIST_QUERY, {
      ...(action.filters ? { filters: action.filters } : {}),
    })

    yield put({
      type: RECEIVE_GET_REGIONS,
      data: data.regionList,
      pageNumber: 0,
      totalCount: 0,
    })
  } catch(error) {
    yield put({ type: FAILED_GET_REGIONS, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* getRegions(action) {
  try {
    const data = yield call(graphql.query, REGIONS_QUERY, {
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      ...(action.filters ? { filters: action.filters } : {}),
      ...(action.sorting ? { sorting: action.sorting } : {}),
    })

    yield put({
      type: RECEIVE_GET_REGIONS,
      data: data.regions.data,
      pageNumber: action.pageNumber,
      totalCount: data.regions.total,
    })
  } catch(error) {
    yield put({ type: FAILED_GET_REGIONS, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* persistRegion(action) {
  try {
    delete action.data.__typename

    const data = yield call(graphql.mutate, REGION_MUTATION, action.data)

    yield put({
      type: RECEIVE_PERSIST_REGION,
      data: data.region,
    })

    const notification = successMessage(savedMessage('region'))
    yield put({ type: NOTIFY, notification })
  } catch(error) {
    yield put({ type: FAILED_PERSIST_REGION, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* deleteRegion(action) {
  try {
    yield call(api.delete, `/region/${action.id}`)

    const notification = warningMessage(deletedMessage('region'))
    yield put({ type: NOTIFY, notification })
  } catch(error) {
    yield put({ type: FAILED_DELETE_REGION, error })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_REGION, getRegion)
  yield takeLatest(REQUEST_GET_REGION_LIST, getRegionList)
  yield takeLatest(REQUEST_GET_REGIONS, getRegions)
  yield takeLatest(REQUEST_PERSIST_REGION, persistRegion)
  yield takeLatest(REQUEST_DELETE_REGION, deleteRegion)
}
