import axios from 'axios'
import url from 'url'
import { ApiError } from './errors'
import store from 'config/store'

axios.defaults.xsrfCookieName = process.env.RAZZLE_API_CSRF_COOKIE || 'XSRF-TOKEN';

const getUrl = (path) => url.parse(path, true).host
  ? decodeURIComponent(path)
  : `${process.env.RAZZLE_API_URI || 'http://127.0.0.1:8080'}${decodeURIComponent(path)}`

const executeApiCall = (method, path, data, params) => {
  const endpoint = getUrl(path)

  return axios(endpoint, {
    method: method,
    data,
    paramsSerializer: (params) => Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join("&"),
    withCredentials: true,
    ...params,
  })
  .then(response => response.data)
  .catch(error => ApiError.handleError(error))
}

export default {
  delete: (path, params = {}) => executeApiCall('DELETE', path, null, params),
  get: (path, params = {}) => executeApiCall('GET', path, null, params),
  post: (path, data, params = {}) => executeApiCall('POST', path, data, params),
  put: (path, data, params = {}) => executeApiCall('PUT', path, data, params)
}
