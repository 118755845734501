import {call, takeLatest, put} from 'redux-saga/effects'
import graphql from 'services/graphql'
import api from 'services/api'
import {
  errorMessageFromGraphQL,
  successMessage,
  warningMessage,
  deletedMessage,
  savedMessage
} from 'config/toast'

import {
  FAILED_GET_CUSTOMER,
  FAILED_GET_CUSTOMERS,
  FAILED_PERSIST_CUSTOMER,
  FAILED_DELETE_CUSTOMER,
  RECEIVE_GET_CUSTOMER,
  RECEIVE_GET_CUSTOMERS,
  RECEIVE_PERSIST_CUSTOMER,
  RECEIVE_DELETE_CUSTOMER,
  REQUEST_GET_CUSTOMER,
  REQUEST_GET_CUSTOMERS,
  REQUEST_PERSIST_CUSTOMER,
  REQUEST_DELETE_CUSTOMER,
} from 'redux/actions/customer'

import { NOTIFY } from 'redux/actions/notification'

import {CUSTOMER_QUERY, CUSTOMERS_QUERY} from 'graphql/queries/customer'
import {CUSTOMER_MUTATION} from 'graphql/mutations/customer'

function* getCustomer(action) {
  try {
    const data = yield call(graphql.query, CUSTOMER_QUERY, { id: action.id })

    yield put({ type: RECEIVE_GET_CUSTOMER, data: data.customer })
  } catch(error) {
    yield put({ type: FAILED_GET_CUSTOMER, error })
  }
}

function* getCustomers(action) {
  try {
    const data = yield call(graphql.query, CUSTOMERS_QUERY, {
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      ...(action.filters ? { filters: action.filters } : {}),
      ...(action.sorting ? { sorting: action.sorting } : {}),
    })

    yield put({
      type: RECEIVE_GET_CUSTOMERS,
      data: data.customers.data,
      pageNumber: action.pageNumber,
      totalCount: data.customers.total,
    })
  } catch(error) {
    yield put({ type: FAILED_GET_CUSTOMERS, error })
  }
}

function* persistCustomer(action) {
  try {
    delete action.data.__typename

    const data = yield call(graphql.mutate, CUSTOMER_MUTATION, action.data)

    yield put({
      type: RECEIVE_PERSIST_CUSTOMER,
      data: data.customer,
    })

    const notification = successMessage(savedMessage('customer'))
    yield put({ type: NOTIFY, notification })
  } catch(error) {
    yield put({ type: FAILED_PERSIST_CUSTOMER, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* deleteCustomer(action) {
  try {
    yield call(api.delete, `/customer/${action.id}`)

    const notification = warningMessage(deletedMessage('customer'))
    yield put({ type: NOTIFY, notification })
  } catch(error) {
    yield put({ type: FAILED_DELETE_CUSTOMER, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_CUSTOMER, getCustomer)
  yield takeLatest(REQUEST_GET_CUSTOMERS, getCustomers)
  yield takeLatest(REQUEST_PERSIST_CUSTOMER, persistCustomer)
  yield takeLatest(REQUEST_DELETE_CUSTOMER, deleteCustomer)
}
