export const RECEIVE_DELETE_REGION = 'RECEIVE_DELETE_REGION'
export const RECEIVE_GET_REGION = 'RECEIVE_GET_REGION'
export const RECEIVE_GET_REGION_LIST = 'RECEIVE_GET_REGION_LIST'
export const RECEIVE_GET_REGIONS = 'RECEIVE_GET_REGIONS'
export const RECEIVE_PERSIST_REGION = 'RECEIVE_PERSIST_REGION'
export const REQUEST_DELETE_REGION = 'REQUEST_DELETE_REGION'
export const REQUEST_GET_REGION = 'REQUEST_GET_REGION'
export const REQUEST_GET_REGION_LIST = 'REQUEST_GET_REGION_LIST'
export const REQUEST_GET_REGIONS = 'REQUEST_GET_REGIONS'
export const REQUEST_PERSIST_REGION = 'REQUEST_PERSIST_REGION'
export const FAILED_DELETE_REGION = 'FAILED_DELETE_REGION'
export const FAILED_GET_REGION = 'FAILED_GET_REGION'
export const FAILED_GET_REGION_LIST = 'FAILED_GET_REGION_LIST'
export const FAILED_GET_REGIONS = 'FAILED_GET_REGIONS'
export const FAILED_PERSIST_REGION = 'FAILED_PERSIST_REGION'
