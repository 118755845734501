export const COMPANY_MUTATION = `
mutation(
  $id: ID
  $chamberOfCommerceNumber: Int!
  $commissionPercentage: Int!
  $email: String!
  $financeEmail: String!
  $name: String!
  $notifyCompanyUsers: Boolean
  $phone: String!
  $privacyStatementUrl: String!
  $regionId: ID!
  $termsAndConditionsUrl: String!
  $vatNumber: String!
) {
  company(
    id: $id
    chamberOfCommerceNumber: $chamberOfCommerceNumber
    commissionPercentage: $commissionPercentage
    email: $email
    financeEmail: $financeEmail
    name: $name
    notifyCompanyUsers: $notifyCompanyUsers
    phone: $phone
    privacyStatementUrl: $privacyStatementUrl
    regionId: $regionId
    termsAndConditionsUrl: $termsAndConditionsUrl
    vatNumber: $vatNumber
  ) {
    id
    chamberOfCommerceNumber
    commissionPercentage
    email
    financeEmail
    mollieIsConnected
    name
    notifyCompanyUsers
    phone
    privacyStatementUrl
    region {
      id
    }
    termsAndConditionsUrl
    vatNumber
    # user {
    #   id
    # }
  }
}
`
