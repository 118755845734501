import { ApolloClient, gql } from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { isServer } from 'config/helpers'
import { i18nextInstance as i18n } from 'config/i18next'

const httpLink = new HttpLink({
  uri: process.env.RAZZLE_GRAPHQL_URI,
  credentials: 'include',
  ...(isServer ? {fetch: require('node-fetch')} : {})
})

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    ...(process.env.NODE_ENV === 'development' ? {
      Authorization: `Bearer ${process.env.RAZZLE_GRAPHQL_BEARER_TOKEN}`
    } : {})
  }
}))

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false })
})

export default {
  query: (query, variables) => {
    return client.query({
      query: gql`${query}`,
      variables: {
        ...variables,
        i18nLanguage: i18n.language
      },
      fetchPolicy: 'network-only'
    })
      .then(res => res.data)
  },
  mutate: (mutation, variables) => {
    return client.mutate({
      mutation: gql`${mutation}`,
      variables: {
        ...variables,
        i18nLanguage: i18n.language
      },
    })
      .then(res => res.data)
  },
}
