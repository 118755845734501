export const FAILED_AUTHORISE_ACCOUNTING = 'FAILED_AUTHORISE_ACCOUNTING'
export const FAILED_AUTHORISE_ACCOUNTING_CALLBACK = 'FAILED_AUTHORISE_ACCOUNTING_CALLBACK'
export const FAILED_AUTHORISE_PAYMENTS = 'FAILED_AUTHORISE_PAYMENTS'
export const FAILED_AUTHORISE_PAYMENTS_CALLBACK = 'FAILED_AUTHORISE_PAYMENTS_CALLBACK'
export const FAILED_DELETE_COMPANY = 'FAILED_DELETE_COMPANY'
export const FAILED_GET_COMPANY = 'FAILED_GET_COMPANY'
export const FAILED_GET_COMPANY_LIST = 'FAILED_GET_COMPANY_LIST'
export const FAILED_GET_COMPANIES = 'FAILED_GET_COMPANIES'
export const FAILED_PERSIST_COMPANY = 'FAILED_PERSIST_COMPANY'
export const RECEIVE_AUTHORISE_ACCOUNTING = 'RECEIVE_AUTHORISE_ACCOUNTING'
export const RECEIVE_AUTHORISE_ACCOUNTING_CALLBACK = 'RECEIVE_AUTHORISE_ACCOUNTING_CALLBACK'
export const RECEIVE_AUTHORISE_PAYMENTS = 'RECEIVE_AUTHORISE_PAYMENTS'
export const RECEIVE_AUTHORISE_PAYMENTS_CALLBACK = 'RECEIVE_AUTHORISE_PAYMENTS_CALLBACK'
export const RECEIVE_DELETE_COMPANY = 'RECEIVE_DELETE_COMPANY'
export const RECEIVE_GET_COMPANY = 'RECEIVE_GET_COMPANY'
export const RECEIVE_GET_COMPANY_LIST = 'RECEIVE_GET_COMPANY_LIST'
export const RECEIVE_GET_COMPANIES = 'RECEIVE_GET_COMPANIES'
export const RECEIVE_PERSIST_COMPANY = 'RECEIVE_PERSIST_COMPANY'
export const REQUEST_AUTHORISE_ACCOUNTING = 'REQUEST_AUTHORISE_ACCOUNTING'
export const REQUEST_AUTHORISE_ACCOUNTING_CALLBACK = 'REQUEST_AUTHORISE_ACCOUNTING_CALLBACK'
export const REQUEST_AUTHORISE_PAYMENTS = 'REQUEST_AUTHORISE_PAYMENTS'
export const REQUEST_AUTHORISE_PAYMENTS_CALLBACK = 'REQUEST_AUTHORISE_PAYMENTS_CALLBACK'
export const REQUEST_DELETE_COMPANY = 'REQUEST_DELETE_COMPANY'
export const REQUEST_GET_COMPANY = 'REQUEST_GET_COMPANY'
export const REQUEST_GET_COMPANY_LIST = 'REQUEST_GET_COMPANY_LIST'
export const REQUEST_GET_COMPANIES = 'REQUEST_GET_COMPANIES'
export const REQUEST_PERSIST_COMPANY = 'REQUEST_PERSIST_COMPANY'
