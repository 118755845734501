export const FAILED_CREATE_QUOTE = 'FAILED_CREATE_QUOTE'
export const FAILED_DELETE_QUOTE = 'FAILED_DELETE_QUOTE'
export const FAILED_GET_NOTIFICAITON_TEMPLATE = 'FAILED_GET_NOTIFICAITON_TEMPLATE'
export const FAILED_GET_QUOTE = 'FAILED_GET_QUOTE'
export const FAILED_GET_QUOTES = 'FAILED_GET_QUOTES'
export const FAILED_PERSIST_QUOTE = 'FAILED_PERSIST_QUOTE'
export const FAILED_SEND_QUOTE = 'FAILED_SEND_QUOTE'
export const RECEIVE_CREATE_QUOTE = 'RECEIVE_CREATE_QUOTE'
export const RECEIVE_DELETE_QUOTE = 'RECEIVE_DELETE_QUOTE'
export const RECEIVE_GET_NOTIFICATION_TEMPLATE = 'RECEIVE_GET_NOTIFICATION_TEMPLATE'
export const RECEIVE_GET_QUOTE = 'RECEIVE_GET_QUOTE'
export const RECEIVE_GET_QUOTES = 'RECEIVE_GET_QUOTES'
export const RECEIVE_PERSIST_QUOTE = 'RECEIVE_PERSIST_QUOTE'
export const RECEIVE_SEND_QUOTE = 'RECEIVE_SEND_QUOTE'
export const REQUEST_CREATE_QUOTE = 'REQUEST_CREATE_QUOTE'
export const REQUEST_DELETE_QUOTE = 'REQUEST_DELETE_QUOTE'
export const REQUEST_GET_QUOTE = 'REQUEST_GET_QUOTE'
export const REQUEST_GET_QUOTES = 'REQUEST_GET_QUOTES'
export const REQUEST_PERSIST_QUOTE = 'REQUEST_PERSIST_QUOTE'
export const REQUEST_SEND_QUOTE = 'REQUEST_SEND_QUOTE'
export const REQUEST_CREATE_NOTE = 'REQUEST_CREATE_NOTE'
export const REQUEST_REFUND = 'REQUEST_REFUND'
export const FAILED_REFUND = 'FAILED_REFUND'
export const REQUEST_GET_NOTIFICAITON_TEMPLATE = 'REQUEST_GET_NOTIFICAITON_TEMPLATE'
