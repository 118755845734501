import React from 'react'
import { Helmet } from 'react-helmet'
import Routes from 'ui/containers/Routes'
import { i18nextInstance as i18n, I18nextProvider } from 'config/i18next'

// Initiate Tailwind CSS
import 'assets/tailwind.scss'

// Hide annoying iframe thingy
if (process.env.NODE_ENV === 'development') {
  ;(async () => import('assets/dev.scss'))()
}

export default () => (
  <I18nextProvider i18n={i18n}>
    <Helmet
      encodeSpecialCharacters={true}
      defaultTitle="Bootuitjes.nl CMS"
      titleTemplate="%s | Bootuitjes.nl CMS"
    />

    <Routes />
  </I18nextProvider>
)
