import {call, takeLatest, put} from 'redux-saga/effects'
import { push } from 'react-router-redux'
import graphql from 'services/graphql'

import * as experimentActions from 'redux/actions/experiment'
import * as experimentQueries from 'graphql/queries/experiment'

function* getExperimentList(action) {
  try {
    const data = yield call(graphql.query, experimentQueries.EXPERIMENT_LIST_QUERY)

    yield put({
      type: experimentActions.RECEIVE_GET_EXPERIMENT_LIST,
      data: data.experimentList,
    })
  } catch(error) {
    yield put({ type: experimentActions.FAILED_GET_EXPERIMENT_LIST, error })
  }
}

export default function* root() {
  yield takeLatest(experimentActions.REQUEST_GET_EXPERIMENT_LIST, getExperimentList)
}
