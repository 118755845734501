import * as experimentActions from 'redux/actions/experiment'
import {RESET_STATE} from 'redux/actions/global'

const initialState = {
  overview: {
    data: [],
    error: '',
    fetching: true,
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case experimentActions.FAILED_GET_EXPERIMENT_LIST:
      return {
        ...state,
        overview: {
          ...state.overview,
          fetching: false,
        }
      }
    case experimentActions.RECEIVE_GET_EXPERIMENT_LIST:
      return {
        ...state,
        overview: {
          data: action.data,
          fetching: false,
        }
      }
    case RESET_STATE:
      if (action.state === 'experiment') {
        return {
          ...state,
          [action.screen]: initialState[action.screen]
        }
      }
    default:
      return state
  }
}
