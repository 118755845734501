import * as reportActions from 'redux/actions/report'
import {RESET_STATE} from 'redux/actions/global'

const initialState = {
  form: {
    data: {},
    error: '',
    fetching: true,
    sending: false,
    deleting: false
  },
  overview: {
    data: [],
    error: '',
    fetching: true,
    pageNumber: 1,
    totalCount: 0
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case reportActions.FAILED_GET_REPORTS:
      return {
        ...state,
        overview: {
          ...state.overview,
          fetching: false,
        }
      }
    case reportActions.RECEIVE_GET_REPORTS:
      return {
        ...state,
        overview: {
          data: action.data,
          fetching: false,
          pageNumber: action.pageNumber,
          totalCount: action.totalCount,
        }
      }
    case RESET_STATE:
      if (action.state === 'report') {
        return {
          ...state,
          [action.screen]: initialState[action.screen]
        }
      }
    default:
      return state
  }
}
