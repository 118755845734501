import i18next from 'i18next'
import { initReactI18next, I18nextProvider } from 'react-i18next'
import { isServer } from 'config/helpers'
import LanguageDetector from 'i18next-browser-languagedetector'
import locales from 'locales'

// Must match directories under src/common/locales
export const supportedLanguages = {
  en: 'English',
  nl: 'Nederlands',
}

// Must match directories under src/common/locales/{language}
export const namespaces = [
  'boat',
  'chat',
  'company',
  'customer',
  'dashboard',
  'entityForm',
  'global',
  'invoice',
  'includedItemsTable',
  'includedProductsTable',
  'navigation',
  'notes',
  'notification',
  'package',
  'product',
  'productTables',
  'quote',
  'refund',
  'region',
  'report',
  'surcharge',
  'tripDurationsTable',
  'tripRequest',
  'user',
]

// Immediately invoked
export const i18nextInstance = (() => {
  const options = {
    fallbackLng: 'en',
    supportedLngs: Object.keys(supportedLanguages),
    ns: namespaces,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: locales(Object.keys(supportedLanguages), namespaces),
    useSuspense: !isServer,
  }

  i18next.use(initReactI18next)

  // If browser, detect language
  if (!isServer) {
    i18next.use(LanguageDetector)
  }

  // Initiate i18next
  i18next.init(options, (error) => {
    if (error) console.log('i18next error', error)
  })

  return i18next
})()

export { I18nextProvider } from 'react-i18next'
