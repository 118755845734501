export const PACKAGE_MUTATION = `
mutation(
  $id: ID
  $companyId: ID
  $description: I18nFieldInput!
  $i18nLanguage: String
  $includedItems: [IncludedItemInput]
  $imageId: ID
  $minGroupSize: Int
  $name: I18nFieldInput!
  $pricePerPersonInCents: Int
  $products: [ProductInput]
  $surcharges: [SurchargeInput]
  $tripDurations: [TripDurationInput]
)
@i18n(
  language: $i18nLanguage
) {
  package(
    id: $id
    companyId: $companyId
    description: $description
    includedItems: $includedItems
    imageId: $imageId
    minGroupSize: $minGroupSize
    name: $name
    pricePerPersonInCents: $pricePerPersonInCents
    products: $products
    surcharges: $surcharges
    tripDurations: $tripDurations
  ) {
    id
    company {
      id
      name
    }
    description {
      value
    }
    image {
      url
    }
    includedItems {
      id
      name {
        value
      }
    }
    minGroupSize
    name {
      value
    }
    pricePerPersonInCents
    products {
      id
      isDefault
      description {
        value
      }
      forAmountOfPeople
      foodPricingType
      includedPricingType
      name {
        value
      }
      packagePricePerPersonInCents
      pricePerHourInCents
      pricePerPersonInCents
      priceInCents
      type
    }
    surcharges {
      id
      name
      surchargeInCents
      surchargePricingType
    }
    tripDurations {
      id
      isDefault
      durationInSeconds
      name
      pricePerPersonInCents
    }
    # user {
    #   id
    # }
  }
}

`
