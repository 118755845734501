export const BOOKING_QUERY = `
query (
  $id: ID!
) {
  booking (
    id: $id
  ) {
    id
    boat {
      company {
        region {
          name
        }
      }
    }
    customer {
      id
      address {
        city
        country
        housenumber
        streetname
        zipcode
      }
      businessName
      email
      financeEmail
      firstName
      fullName
      isBusiness
      lastName
      phone
    }
    date
    groupSize
    invoice {
      id
    }
    lineItems {
      id
      description
      priceInCents
      quantity
      type
    }
    notes {
      id
      content
      user {
        name
      }
      createdAt
    }
    package {
      company {
        region {
          name
        }
      }
    }
    poNumber
    preventDeletion
    quotes {
      id
    }
    remarks
    status {
      name
    }
    tags {
      tag
    }
  }
}
`

export const NEW_BOOKINGS_COUNT_QUERY = `
query {
  newBookingCount {
    count
  }
}
`

export const BOOKINGS_QUERY = `
query (
  $filters: [FilterInput]
  $pageNumber: Int
  $pageSize: Int
  $sorting: [SortingInput]
) {
  bookings (
    filters: $filters
    pageNumber: $pageNumber
    pageSize: $pageSize
    sorting: $sorting
  ) {
    data {
      id
      boat {
        company {
          name
        }
      }
      customer {
        id
        firstName
        lastName
      }
      date
      groupSize
      package {
        company {
          name
        }
      }
      status {
        name
      }
      tags {
        tag
      }
      createdAt
      updatedAt
    }
    total
  }
}
`
