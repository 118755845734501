export const RECEIVE_DELETE_PRODUCT = 'RECEIVE_DELETE_PRODUCT'
export const RECEIVE_GET_PRODUCT = 'RECEIVE_GET_PRODUCT'
export const RECEIVE_GET_PRODUCT_LIST = 'RECEIVE_GET_PRODUCT_LIST'
export const RECEIVE_GET_PRODUCTS = 'RECEIVE_GET_PRODUCTS'
export const RECEIVE_PERSIST_PRODUCT = 'RECEIVE_PERSIST_PRODUCT'
export const REQUEST_DELETE_PRODUCT = 'REQUEST_DELETE_PRODUCT'
export const REQUEST_GET_PRODUCT = 'REQUEST_GET_PRODUCT'
export const REQUEST_GET_PRODUCT_LIST = 'REQUEST_GET_PRODUCT_LIST'
export const REQUEST_GET_PRODUCTS = 'REQUEST_GET_PRODUCTS'
export const REQUEST_PERSIST_PRODUCT = 'REQUEST_PERSIST_PRODUCT'
export const FAILED_DELETE_PRODUCT = 'FAILED_DELETE_PRODUCT'
export const FAILED_GET_PRODUCT = 'FAILED_GET_PRODUCT'
export const FAILED_GET_PRODUCT_LIST = 'FAILED_GET_PRODUCT_LIST'
export const FAILED_GET_PRODUCTS = 'FAILED_GET_PRODUCTS'
export const FAILED_PERSIST_PRODUCT = 'FAILED_PERSIST_PRODUCT'
