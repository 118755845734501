import {
  FAILED_GET_SURCHARGE,
  FAILED_PERSIST_SURCHARGE,
  RECEIVE_GET_SURCHARGE,
  RECEIVE_GET_SURCHARGES,
  RECEIVE_PERSIST_SURCHARGE,
  REQUEST_PERSIST_SURCHARGE,
} from 'redux/actions/surcharge'
import { RESET_STATE } from 'redux/actions/global'

const initialState = {
  form: {
    data: {},
    error: '',
    fetching: true,
    sending: false,
    deleting: false,
  },
  overview: {
    data: [],
    error: '',
    fetching: true,
    pageNumber: 1,
    totalCount: 0,
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FAILED_GET_SURCHARGE:
    case FAILED_PERSIST_SURCHARGE:
      return {
        ...state,
        form: {
          ...state.form,
          fetching: false,
          sending: false,
          deleting: false,
        },
      }
    case RECEIVE_PERSIST_SURCHARGE:
    case RECEIVE_GET_SURCHARGE:
      return {
        ...state,
        form: {
          data: action.data,
          fetching: false,
          sending: false,
        },
      }
    case RECEIVE_GET_SURCHARGES:
      return {
        ...state,
        overview: {
          data: action.data,
          fetching: false,
          pageNumber: action.pageNumber,
          totalCount: action.totalCount,
        },
      }
    case REQUEST_PERSIST_SURCHARGE:
      return {
        ...state,
        form: {
          ...state.form,
          sending: true,
        },
      }
    case RESET_STATE:
      if (action.state === 'surcharge') {
        return {
          ...state,
          [action.screen]: initialState[action.screen],
        }
      }
    default:
      return state
  }
}
