import { call, takeLatest, put } from 'redux-saga/effects'
import graphql from 'services/graphql'
import qs from 'query-string'
import api from 'services/api'
import {
  errorMessageFromGraphQL,
  successMessage,
  warningMessage,
  deletedMessage,
  savedMessage,
} from 'config/toast'

import {
  FAILED_AUTHORISE_ACCOUNTING,
  FAILED_AUTHORISE_ACCOUNTING_CALLBACK,
  FAILED_AUTHORISE_PAYMENTS,
  FAILED_AUTHORISE_PAYMENTS_CALLBACK,
  FAILED_GET_COMPANY,
  FAILED_GET_COMPANIES,
  FAILED_PERSIST_COMPANY,
  FAILED_DELETE_COMPANY,
  RECEIVE_AUTHORISE_ACCOUNTING_CALLBACK,
  RECEIVE_AUTHORISE_PAYMENTS_CALLBACK,
  RECEIVE_GET_COMPANY,
  RECEIVE_GET_COMPANIES,
  RECEIVE_PERSIST_COMPANY,
  REQUEST_AUTHORISE_ACCOUNTING,
  REQUEST_AUTHORISE_ACCOUNTING_CALLBACK,
  REQUEST_AUTHORISE_PAYMENTS,
  REQUEST_AUTHORISE_PAYMENTS_CALLBACK,
  REQUEST_GET_COMPANY,
  REQUEST_GET_COMPANY_LIST,
  REQUEST_GET_COMPANIES,
  REQUEST_PERSIST_COMPANY,
  REQUEST_DELETE_COMPANY,
} from 'redux/actions/company'

import { NOTIFY } from 'redux/actions/notification'

import { COMPANY_QUERY, COMPANY_LIST_QUERY, COMPANIES_QUERY } from 'graphql/queries/company'
import { COMPANY_MUTATION } from 'graphql/mutations/company'

function* authoriseAccounting(action) {
  try {
    const { redirectUrl } = yield call(
      api.get,
      `/moneybird/auth?companyId=${action.id}&clientId=${action.clientId}&clientSecret=${action.clientSecret}`
    )

    window.location.href = redirectUrl
  } catch (error) {
    yield put({ type: FAILED_AUTHORISE_ACCOUNTING, error })
  }
}

function* authoriseAccountingCallback(action) {
  try {
    const params = qs.stringify(action.params)
    const data = yield call(api.get, `/moneybird/auth/callback?${params}`)

    yield put({ type: RECEIVE_AUTHORISE_ACCOUNTING_CALLBACK, data })
  } catch (error) {
    yield put({ type: FAILED_AUTHORISE_ACCOUNTING_CALLBACK, error })
  }
}

function* authorisePayments(action) {
  try {
    const { redirectUrl } = yield call(api.get, `/mollie/auth?companyId=${action.id}`)

    window.location.href = redirectUrl
  } catch (error) {
    yield put({ type: FAILED_AUTHORISE_PAYMENTS, error })
  }
}

function* authorisePaymentsCallback(action) {
  try {
    const params = qs.stringify(action.params)
    const data = yield call(api.get, `/mollie/auth/callback?${params}`)

    yield put({ type: RECEIVE_AUTHORISE_PAYMENTS_CALLBACK, data })
  } catch (error) {
    yield put({ type: FAILED_AUTHORISE_PAYMENTS_CALLBACK, error })
  }
}

function* getCompany(action) {
  try {
    const data = yield call(graphql.query, COMPANY_QUERY, { id: action.id })

    yield put({ type: RECEIVE_GET_COMPANY, data: data.company })
  } catch (error) {
    yield put({ type: FAILED_GET_COMPANY, error })
    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* getCompanies(action) {
  try {
    const data = yield call(graphql.query, COMPANIES_QUERY, {
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      ...(action.filters ? { filters: action.filters } : {}),
      ...(action.sorting ? { sorting: action.sorting } : {}),
    })

    yield put({
      type: RECEIVE_GET_COMPANIES,
      data: data.companies.data,
      pageNumber: action.pageNumber,
      totalCount: data.companies.total,
    })
  } catch (error) {
    yield put({ type: FAILED_GET_COMPANIES, error })
    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* getCompanyList(action) {
  try {
    const data = yield call(graphql.query, COMPANY_LIST_QUERY, {
      ...(action.filters ? { filters: action.filters } : {}),
    })

    yield put({
      type: RECEIVE_GET_COMPANIES,
      data: data.companyList,
      pageNumber: 0,
      totalCount: 0,
    })
  } catch (error) {
    yield put({ type: FAILED_GET_COMPANIES, error })
    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* persistCompany(action) {
  try {
    delete action.data.__typename

    const data = yield call(graphql.mutate, COMPANY_MUTATION, action.data)

    yield put({
      type: RECEIVE_PERSIST_COMPANY,
      data: data.company,
    })

    const notification = successMessage(savedMessage('company'))
    yield put({ type: NOTIFY, notification })
  } catch (error) {
    yield put({ type: FAILED_PERSIST_COMPANY, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* deleteCompany(action) {
  try {
    yield call(api.delete, `/company/${action.id}`)

    const notification = warningMessage(deletedMessage('company'))
    yield put({ type: NOTIFY, notification })
  } catch (error) {
    yield put({ type: FAILED_DELETE_COMPANY, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_AUTHORISE_ACCOUNTING, authoriseAccounting)
  yield takeLatest(REQUEST_AUTHORISE_ACCOUNTING_CALLBACK, authoriseAccountingCallback)
  yield takeLatest(REQUEST_AUTHORISE_PAYMENTS, authorisePayments)
  yield takeLatest(REQUEST_AUTHORISE_PAYMENTS_CALLBACK, authorisePaymentsCallback)
  yield takeLatest(REQUEST_GET_COMPANY, getCompany)
  yield takeLatest(REQUEST_GET_COMPANY_LIST, getCompanyList)
  yield takeLatest(REQUEST_GET_COMPANIES, getCompanies)
  yield takeLatest(REQUEST_PERSIST_COMPANY, persistCompany)
  yield takeLatest(REQUEST_DELETE_COMPANY, deleteCompany)
}
