export const BOAT_MUTATION = `
mutation(
  $id: ID
  $companyId: ID
  $corkageInCents: Int
  $corkagePerPersonInCents: Int
  $corkageType: String
  $description: I18nFieldInput!
  $i18nLanguage: String
  $includedItems: [IncludedItemInput]
  $imageId: ID
  $minGroupSize: Int
  $name: String!
  $pricePerHourInCents: Int
  $pricePerPersonInCents: Int
  $pricingType: String
  $products: [ProductInput]
  $surcharges: [SurchargeInput]
  $tripDurations: [TripDurationInput]
)
@i18n(
  language: $i18nLanguage
) {
  boat(
    id: $id
    companyId: $companyId
    corkageInCents: $corkageInCents
    corkagePerPersonInCents: $corkagePerPersonInCents
    corkageType: $corkageType
    description: $description
    includedItems: $includedItems
    imageId: $imageId
    minGroupSize: $minGroupSize
    name: $name
    pricePerHourInCents: $pricePerHourInCents
    pricePerPersonInCents: $pricePerPersonInCents
    pricingType: $pricingType
    products: $products
    surcharges: $surcharges
    tripDurations: $tripDurations
  ) {
    id
    company {
      id
      name
    }
    corkageInCents
    corkagePerPersonInCents
    corkageType
    description {
      value
    }
    includedItems {
      id
      name {
        value
      }
    }
    image {
      url
    }
    name
    minGroupSize
    pricePerHourInCents
    pricePerPersonInCents
    pricingType
    products {
      id
      isDefault
      description {
        value
      }
      forAmountOfPeople
      foodPricingType
      name {
        value
      }
      pricePerPersonInCents
      priceInCents
      type
    }
    surcharges {
      id
      name
      surchargeInCents
      surchargePricingType
    }
    tripDurations {
      id
      isDefault
      durationInSeconds
      name
      pricePerPersonInCents
    }
    # user {
    #   id
    # }
  }
}

`
