export const CUSTOMER_QUERY = `
query (
  $id: ID!
) {
  customer (
    id: $id
  ) {
    id
    address {
      id
      city
      country
      housenumber
      streetname
      zipcode
    }
    businessName
    email
    firstName
    isBusiness
    lastName
    phone
  }
}
`

export const CUSTOMERS_QUERY = `
query (
  $filters: [FilterInput]
  $pageNumber: Int
  $pageSize: Int
  $sorting: [SortingInput]
) {
  customers (
    filters: $filters
    pageNumber: $pageNumber
    pageSize: $pageSize
    sorting: $sorting
  ) {
    data {
      id
      firstName
      lastName
      createdAt
      updatedAt
    }
    total
  }
}
`
