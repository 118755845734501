import React, { useEffect } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Redirect, Route, Switch } from 'react-router-dom'
import loadable from '@loadable/component'
import { ROLE_ADMINISTRATOR, ROLE_COMPANY_ADMIN } from 'config/user'
import { isServer } from 'config/helpers'

// Import components
import Loader from 'ui/components/Loader'
import PrivateRoute from 'ui/components/PrivateRoute'
import PublicRoute from 'ui/components/PublicRoute'
// import NotFound from 'ui/pages/Error/NotFound'

const options = {
  fallback: <Loader />,
}

// Loadable
const Login = loadable(() => import('ui/containers/Authentication/Login'), options)
const PasswordForgot = loadable(
  () => import('ui/containers/Authentication/PasswordForgot'),
  options
)
const PasswordReset = loadable(() => import('ui/containers/Authentication/PasswordReset'), options)
const Dashboard = loadable(() => import('ui/containers/Dashboard'), options)
const BoatForm = loadable(() => import('ui/containers/Boat/Form'), options)
const BoatOverview = loadable(() => import('ui/containers/Boat/Overview'), options)
const CompanyAccountingAuthCallback = loadable(
  () => import('ui/containers/Company/AccountingAuthCallback'),
  options
)
const CompanyMollieAuthCallback = loadable(
  () => import('ui/containers/Company/MollieAuthCallback'),
  options
)
const CompanyForm = loadable(() => import('ui/containers/Company/Form'), options)
const CompanyOverview = loadable(() => import('ui/containers/Company/Overview'), options)
const Experiments = loadable(() => import('ui/containers/Experiments'), options)
const CustomerForm = loadable(() => import('ui/containers/Customer/Form'), options)
const CustomerOverview = loadable(() => import('ui/containers/Customer/Overview'), options)
const InvoiceForm = loadable(() => import('ui/containers/Invoice/Form'), options)
const InvoiceOverview = loadable(() => import('ui/containers/Invoice/Overview'), options)
const PackageForm = loadable(() => import('ui/containers/Package/Form'), options)
const PackageOverview = loadable(() => import('ui/containers/Package/Overview'), options)
const ProductForm = loadable(() => import('ui/containers/Product/Form'), options)
const ProductOverview = loadable(() => import('ui/containers/Product/Overview'), options)
const QuoteOverview = loadable(() => import('ui/containers/Quote/Overview'), options)
const Reports = loadable(() => import('ui/containers/Reports'), options)
const RegionForm = loadable(() => import('ui/containers/Region/Form'), options)
const RegionOverview = loadable(() => import('ui/containers/Region/Overview'), options)
const SurchargeForm = loadable(() => import('ui/containers/Surcharge/Form'), options)
const SurchargeOverview = loadable(() => import('ui/containers/Surcharge/Overview'), options)
const TripRequestCreate = loadable(() => import('ui/containers/TripRequest/Create'), options)
const TripRequestFlow = loadable(() => import('ui/containers/TripRequest/Flow'), options)
const TripRequestOverview = loadable(() => import('ui/containers/TripRequest/Overview'), options)
const UserForm = loadable(() => import('ui/containers/User/Form'), options)
const UserOverview = loadable(() => import('ui/containers/User/Overview'), options)

export default ({ authenticated, checked, role, userAuthStatus, ...props }) => {
  useEffect(() => {
    userAuthStatus()
  }, [props.location])

  // Server can skip auth check (should also move this check to the private route component)
  if (typeof window !== 'undefined' && !checked) {
    return <Loader />
  } else {
    return (
      <Route
        render={({ location }) => {
          return (
            <TransitionGroup component={null}>
              <CSSTransition key={location.key} classNames="fade" timeout={300}>
                <Switch location={location}>
                  <PublicRoute
                    authenticated={authenticated}
                    exact
                    path="/login"
                    render={() => <Login />}
                    strict
                  />

                  <PublicRoute
                    authenticated={authenticated}
                    exact
                    path="/password/forgot"
                    render={() => <PasswordForgot />}
                    strict
                  />

                  <PublicRoute
                    authenticated={authenticated}
                    exact
                    path="/password/reset"
                    render={(match) => <PasswordReset match={match} />}
                    strict
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/"
                    render={() => {
                      if (isServer) {
                        return null
                      }

                      switch (role) {
                        case ROLE_ADMINISTRATOR:
                          return <Dashboard />
                        default:
                          return <Redirect to="/tripRequests" />
                      }
                    }}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/boats"
                    render={() => <BoatOverview />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/boat"
                    render={() => <BoatForm />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/boat/:id"
                    render={({ match }) => <BoatForm id={match.params.id} />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/tripRequests"
                    render={() => <TripRequestOverview />}
                    strict
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/tripRequest"
                    render={() => <TripRequestCreate />}
                    strict
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/tripRequest/:id"
                    render={({ match }) => <TripRequestFlow id={match.params.id} />}
                    strict
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/companies"
                    render={() => <CompanyOverview />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/company"
                    render={() => <CompanyForm />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/company/:id"
                    render={({ match }) => <CompanyForm id={match.params.id} />}
                    roles={[ROLE_ADMINISTRATOR, ROLE_COMPANY_ADMIN]}
                    permissions={(params) => [
                      ...(role === ROLE_COMPANY_ADMIN
                        ? [params.id === props?.meta?.companyId]
                        : []),
                    ]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/company/auth/callback"
                    render={() => <CompanyMollieAuthCallback />}
                    roles={[ROLE_ADMINISTRATOR, ROLE_COMPANY_ADMIN]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/company/accounting/callback"
                    render={() => <CompanyAccountingAuthCallback />}
                    roles={[ROLE_ADMINISTRATOR, ROLE_COMPANY_ADMIN]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/customers"
                    render={() => <CustomerOverview />}
                    strict
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/customer"
                    render={() => <CustomerForm />}
                    strict
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/customer/:id"
                    render={({ match }) => <CustomerForm id={match.params.id} />}
                    strict
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/experiments"
                    render={() => <Experiments />}
                    strict
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/invoices"
                    render={() => <InvoiceOverview />}
                    strict
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/invoice"
                    render={() => <InvoiceForm />}
                    strict
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/invoice/:id"
                    render={({ match }) => <InvoiceForm id={match.params.id} />}
                    strict
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/products"
                    render={() => <ProductOverview />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/product"
                    render={() => <ProductForm />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/product/:id"
                    render={({ match }) => <ProductForm id={match.params.id} />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/packages"
                    render={() => <PackageOverview />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/package"
                    render={() => <PackageForm />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/package/:id"
                    render={({ match }) => <PackageForm id={match.params.id} />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/quotes"
                    render={() => <QuoteOverview />}
                    strict
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/quote"
                    render={() => <QuoteForm />}
                    strict
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/quote/:id"
                    render={({ match }) => <QuoteForm id={match.params.id} />}
                    strict
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/regions"
                    render={() => <RegionOverview />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/region"
                    render={() => <RegionForm />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/region/:id"
                    render={({ match }) => <RegionForm id={match.params.id} />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/reports"
                    render={() => <Reports />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/surcharges"
                    render={() => <SurchargeOverview />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/surcharge"
                    render={() => <SurchargeForm />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/surcharge/:id"
                    render={({ match }) => <SurchargeForm id={match.params.id} />}
                    roles={[ROLE_ADMINISTRATOR]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/users"
                    render={() => <UserOverview />}
                    roles={[ROLE_ADMINISTRATOR, ROLE_COMPANY_ADMIN]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/user"
                    render={() => <UserForm />}
                    roles={[ROLE_ADMINISTRATOR, ROLE_COMPANY_ADMIN]}
                    strict
                    userRole={role}
                  />

                  <PrivateRoute
                    authenticated={authenticated}
                    exact
                    path="/user/:id"
                    render={({ match }) => <UserForm id={match.params.id} />}
                    roles={[ROLE_ADMINISTRATOR, ROLE_COMPANY_ADMIN]}
                    strict
                    userRole={role}
                  />

                  {/*<Route component={NotFound} />*/}
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )
        }}
      />
    )
  }
}
