export const EXPERIMENT_LIST_QUERY = `
query {
  experimentList {
    id
    label
    start
    end
    variants {
      id
      label
      totalPlays
      totalWins
      value
      weight
    }
  }
}
`
