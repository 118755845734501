export const PACKAGE_QUERY = `
query (
  $id: ID!
  $i18nLanguage: String
)
@i18n (
  language: $i18nLanguage
)
{
  package (
    id: $id
  ) {
    id
    company {
      id
      name
    }
    description {
      value
    }
    image {
      url
    }
    includedItems {
      id
      name {
        value
      }
    }
    minGroupSize
    name {
      value
    }
    pricePerPersonInCents
    products {
      id
      isDefault
      description {
        value
      }
      forAmountOfPeople
      foodPricingType
      includedPricingType
      name {
        value
      }
      packagePricePerPersonInCents
      pricePerHourInCents
      pricePerPersonInCents
      pricePerTripInCents
      priceInCents
      type
    }
    surcharges {
      id
      name
      surchargeInCents
      surchargePricingType
    }
    tripDurations {
      id
      isDefault
      durationInSeconds
      name
      pricePerPersonInCents
    }
  }
}
`

export const PACKAGE_LIST_QUERY = `
query (
  $i18nLanguage: String
  $filters: [FilterInput]
) @i18n (
  language: $i18nLanguage
) {
  packageList (
    filters: $filters
  ) {
    id
    name {
      value
    }
    company {
      name
    }
  }
}
`

export const PACKAGES_QUERY = `
query (
  $filters: [FilterInput]
  $i18nLanguage: String
  $pageNumber: Int
  $pageSize: Int
  $sorting: [SortingInput]
)
@i18n (
  language: $i18nLanguage
) {
  packages (
    filters: $filters
    pageNumber: $pageNumber
    pageSize: $pageSize
    sorting: $sorting
  ) {
    data {
      id
      company {
        name
      }
      description {
        value
      }
      minGroupSize
      name {
        value
      }
      createdAt
      updatedAt
    }
    total
  }
}
`
