export const RECEIVE_DELETE_PACKAGE = 'RECEIVE_DELETE_PACKAGE'
export const RECEIVE_GET_PACKAGE = 'RECEIVE_GET_PACKAGE'
export const RECEIVE_GET_PACKAGE_LIST = 'RECEIVE_GET_PACKAGE_LIST'
export const RECEIVE_GET_PACKAGES = 'RECEIVE_GET_PACKAGES'
export const RECEIVE_PERSIST_PACKAGE = 'RECEIVE_PERSIST_PACKAGE'
export const REQUEST_DELETE_PACKAGE = 'REQUEST_DELETE_PACKAGE'
export const REQUEST_GET_PACKAGE = 'REQUEST_GET_PACKAGE'
export const REQUEST_GET_PACKAGE_LIST = 'REQUEST_GET_PACKAGE_LIST'
export const REQUEST_GET_PACKAGES = 'REQUEST_GET_PACKAGES'
export const REQUEST_PERSIST_PACKAGE = 'REQUEST_PERSIST_PACKAGE'
export const FAILED_DELETE_PACKAGE = 'FAILED_DELETE_PACKAGE'
export const FAILED_GET_PACKAGE = 'FAILED_GET_PACKAGE'
export const FAILED_GET_PACKAGE_LIST = 'FAILED_GET_PACKAGE_LIST'
export const FAILED_GET_PACKAGES = 'FAILED_GET_PACKAGES'
export const FAILED_PERSIST_PACKAGE = 'FAILED_PERSIST_PACKAGE'
