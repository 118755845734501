export const QUOTE_QUERY = `
query (
  $id: ID!
) {
  quote (
    id: $id
  ) {
    id
    booking {
      id
      date
      groupSize
      remarks
      status {
        name
      }
      notes {
        id
        content
        user {
          name
        }
        createdAt
      }
      poNumber
    }
    company {
      region {
        name
      }
    }
    customer {
      id
      address {
        id
        city
        country
        housenumber
        streetname
        zipcode
      }
      businessName
      email
      firstName
      financeEmail
      isBusiness
      lastName
      phone
    }
    expirationDate
    isExpired
    isPaid
    lineItems {
      id
      description
      priceInCents
      quantity
      type
      vatRate
    }
    overrideExpirationDate
    remarks {
      body
      type
    }
    useOverrideExpirationDate
  }
}
`

export const QUOTES_QUERY = `
query (
  $filters: [FilterInput]
  $pageNumber: Int
  $pageSize: Int
  $sorting: [SortingInput]
) {
  quotes (
    filters: $filters
    pageNumber: $pageNumber
    pageSize: $pageSize
    sorting: $sorting
  ) {
    data {
      id
      company {
        name
      }
      customer {
        id
        firstName
        financeEmail
        fullName
        lastName
      }
      createdAt
      updatedAt
    }
    total
  }
}
`
