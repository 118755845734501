export const PRODUCT_QUERY = `
query (
  $id: ID!
  $i18nLanguage: String
)
@i18n (
  language: $i18nLanguage
) {
  product (
    id: $id
  ) {
    id
    applyCommission
    company {
      id
      name
    }
    description {
      value
    }
    forAmountOfPeople
    foodPricingType
    image {
      url
    }
    includedPricingType
    name {
      value
    }
    packagePricePerPersonInCents
    pricePerHourInCents
    pricePerPersonInCents
    pricePerTripInCents
    priceInCents
    type
    vatRate
  }
}
`

export const PRODUCT_LIST_QUERY = `
query (
  $i18nLanguage: String
  $filters: [FilterInput]
) @i18n (
  language: $i18nLanguage
) {
  productList (
    filters: $filters
  ) {
    id
    company {
      id
    }
    forAmountOfPeople
    foodPricingType
    includedPricingType
    name {
      value
    }
    pricePerHourInCents
    pricePerPersonInCents
    pricePerTripInCents
    priceInCents
    type
  }
}
`

export const PRODUCTS_QUERY = `
query (
  $i18nLanguage: String
  $filters: [FilterInput]
  $pageNumber: Int
  $pageSize: Int
  $sorting: [SortingInput]
) @i18n (
  language: $i18nLanguage
) {
  products (
    filters: $filters
    pageNumber: $pageNumber
    pageSize: $pageSize
    sorting: $sorting
  ) {
    data {
      id
      company {
        id
        name
      }
      description {
        value
      }
      forAmountOfPeople
      foodPricingType
      name {
        value
      }
      pricePerPersonInCents
      priceInCents
      type
      createdAt
      updatedAt
    }
    total
  }
}
`
