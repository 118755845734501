export const ROLE_QUERY = `
query ($id: ID!) {
  roles(id: $id) {
    id
    name
  }
}
`

export const ROLES_QUERY = `
query {
  roles {
    id
    name
  }
}
`
