import { call, takeLatest, put } from 'redux-saga/effects'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import { NOTIFY } from 'redux/actions/notification'
import styles from 'assets/Toast.module.scss'

function* notify(action) {
  try {
    yield call(() => {
      switch (action?.notification?.type) {
        case 'error':
        case 'info':
        case 'success':
        case 'warning':
          return toast[action.notification.type](action.notification.message, {
            className: classNames(
              styles[action.notification.type] || null,
              styles.Toast
            )
          })
          break
        default:
          console.log('test')

          return toast(action.notification.message)
          break
      }
    })
  } catch (error) {
    console.error(error)
  }
}

export default function* root() {
  yield takeLatest(NOTIFY, notify)
}
