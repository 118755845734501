export const COMPANY_QUERY = `
query (
  $id: ID!
) {
  company (
    id: $id
  ) {
    id
    accountingIsConfigured
    accountingRedirectUri
    chamberOfCommerceNumber
    commissionPercentage
    email
    financeEmail
    mollieIsConnected
    name
    notifyCompanyUsers
    phone
    privacyStatementUrl
    region {
      id
    }
    termsAndConditionsUrl
    vatNumber
  }
}
`

export const COMPANY_LIST_QUERY = `
query (
  $filters: [FilterInput]
) {
  companyList (
    filters: $filters
  ) {
    id
    name
  }
}
`

export const COMPANIES_QUERY = `
query (
  $filters: [FilterInput]
  $pageNumber: Int
  $pageSize: Int
  $sorting: [SortingInput]
) {
  companies (
    filters: $filters
    pageNumber: $pageNumber
    pageSize: $pageSize
    sorting: $sorting
  ) {
    data {
      id
      name
      createdAt
      updatedAt
    }
    total
  }
}
`
