export const USER_MUTATION = `
mutation(
  $id: ID
  $companyId: ID
  $email: String!
  $name: String!
  $password: String
  $roleId: ID!
) {
  user(
    id: $id
    companyId: $companyId
    email: $email
    name: $name
    password: $password
    roleId: $roleId
  ) {
    id
    company {
      id
      name
    }
    email
    name
    roles {
      id
      name
    }
  }
}
`
