export const INVOICE_QUERY = `
query (
  $id: ID!
) {
  invoice (
    id: $id
  ) {
    id
    booking {
      id
      date
      groupSize
      latestGroupSize
      remarks
      notes {
        id
        content
        user {
          name
        }
        createdAt
      }
      poNumber
    }
    company {
      region {
        name
      }
    }
    customer {
      id
      address {
        city
        country
        housenumber
        streetname
        zipcode
      }
      businessName
      email
      financeEmail
      firstName
      isBusiness
      lastName
      phone
    }
    lineItems {
      id
      description
      priceInCents
      quantity
      latestQuantity
      type
      vatRate
    }
    quote {
      id
    }
    remarks
  }
}
`

export const INVOICES_QUERY = `
query(
  $filters: [FilterInput]
  $pageNumber: Int
  $pageSize: Int
  $sorting: [SortingInput]
) {
  invoices (
    filters: $filters
    pageNumber: $pageNumber
    pageSize: $pageSize
    sorting: $sorting
  ) {
    data {
      id
      company {
        name
      }
      customer {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
    total
  }
}
`
