import { fork } from 'redux-saga/effects'

// Import sagas
import boat from './boat'
import booking from './booking'
import company from './company'
import customer from './customer'
import dashboard from './dashboard'
import experiment from './experiment'
import image from './image'
import invoice from './invoice'
import notification from './notification'
import packageSaga from './package' // package is a reserved word...
import product from './product'
import quote from './quote'
import region from './region'
import report from './report'
import role from './role'
import surcharge from './surcharge'
import user from './user'
import vatRate from './vatRate'

// Export root saga
export default function* root() {
  yield fork(boat)
  yield fork(booking)
  yield fork(company)
  yield fork(customer)
  yield fork(dashboard)
  yield fork(experiment)
  yield fork(image)
  yield fork(invoice)
  yield fork(notification)
  yield fork(packageSaga)
  yield fork(product)
  yield fork(quote)
  yield fork(region)
  yield fork(report)
  yield fork(role)
  yield fork(surcharge)
  yield fork(user)
  yield fork(vatRate)
}
