import {call, takeLatest, put} from 'redux-saga/effects'
import graphql from 'services/graphql'
import api from 'services/api'
import {
  errorMessageFromGraphQL,
  successMessage,
  warningMessage,
  deletedMessage,
  savedMessage
} from 'config/toast'

import {
  FAILED_GET_PACKAGE,
  FAILED_GET_PACKAGES,
  FAILED_PERSIST_PACKAGE,
  FAILED_DELETE_PACKAGE,
  RECEIVE_GET_PACKAGE,
  RECEIVE_GET_PACKAGES,
  RECEIVE_PERSIST_PACKAGE,
  RECEIVE_DELETE_PACKAGE,
  REQUEST_GET_PACKAGE,
  REQUEST_GET_PACKAGE_LIST,
  REQUEST_GET_PACKAGES,
  REQUEST_PERSIST_PACKAGE,
  REQUEST_DELETE_PACKAGE,
} from 'redux/actions/package'

import { NOTIFY } from 'redux/actions/notification'

import {PACKAGE_QUERY, PACKAGE_LIST_QUERY, PACKAGES_QUERY} from 'graphql/queries/package'
import {PACKAGE_MUTATION} from 'graphql/mutations/package'

function* getPackage(action) {
  try {
    const data = yield call(graphql.query, PACKAGE_QUERY, { id: action.id })

    yield put({ type: RECEIVE_GET_PACKAGE, data: data.package })
  } catch(error) {
    yield put({ type: FAILED_GET_PACKAGE, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* getPackageList(action) {
  try {
    const data = yield call(graphql.query, PACKAGE_LIST_QUERY, {
      ...(action.filters ? { filters: action.filters } : {}),
    })

    yield put({
      type: RECEIVE_GET_PACKAGES,
      data: data.packageList,
      pageNumber: 0,
      totalCount: 0,
    })
  } catch(error) {
    yield put({ type: FAILED_GET_PACKAGES, error })
    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* getPackages(action) {
  try {
    const data = yield call(graphql.query, PACKAGES_QUERY, {
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      ...(action.filters ? { filters: action.filters } : {}),
      ...(action.sorting ? { sorting: action.sorting } : {}),
    })

    yield put({
      type: RECEIVE_GET_PACKAGES,
      data: data.packages.data,
      pageNumber: action.pageNumber,
      totalCount: data.packages.total,
    })
  } catch(error) {
    yield put({ type: FAILED_GET_PACKAGES, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* persistPackage(action) {
  try {
    delete action.data.__typename

    // Remove ids from new trip durations
    if (action?.data?.tripDurations?.length > 0) {
      action.data.tripDurations = action.data.tripDurations
        .map(({id, ...duration}) => ({
          ...(id.startsWith('new-') ? {} : {id}),
          ...duration
        }))
    }
    const data = yield call(graphql.mutate, PACKAGE_MUTATION, action.data)

    yield put({
      type: RECEIVE_PERSIST_PACKAGE,
      data: data.package,
    })

    const notification = successMessage(savedMessage('package'))
    yield put({ type: NOTIFY, notification })
  } catch(error) {
    yield put({ type: FAILED_PERSIST_PACKAGE, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* deletePackage(action) {
  try {
    yield call(api.delete, `/package/${action.id}`)

    const notification = warningMessage(deletedMessage('package'))
    yield put({ type: NOTIFY, notification })
  } catch(error) {
    yield put({ type: FAILED_DELETE_PACKAGE, error })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_PACKAGE, getPackage)
  yield takeLatest(REQUEST_GET_PACKAGE_LIST, getPackageList)
  yield takeLatest(REQUEST_GET_PACKAGES, getPackages)
  yield takeLatest(REQUEST_PERSIST_PACKAGE, persistPackage)
  yield takeLatest(REQUEST_DELETE_PACKAGE, deletePackage)
}
