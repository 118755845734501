import { call, takeLatest, put } from 'redux-saga/effects'
import graphql from 'services/graphql'
import api from 'services/api'
import {
  errorMessage,
  errorMessageFromGraphQL,
  successMessage,
  warningMessage,
  deletedMessage,
  savedMessage,
} from 'config/toast'

import {
  FAILED_CREATE_QUOTE,
  FAILED_DELETE_QUOTE,
  FAILED_GET_QUOTE,
  FAILED_GET_QUOTES,
  FAILED_PERSIST_QUOTE,
  FAILED_SEND_QUOTE,
  RECEIVE_CREATE_QUOTE,
  RECEIVE_GET_NOTIFICATION_TEMPLATE,
  RECEIVE_GET_QUOTE,
  RECEIVE_GET_QUOTES,
  RECEIVE_PERSIST_QUOTE,
  RECEIVE_SEND_QUOTE,
  REQUEST_CREATE_QUOTE,
  REQUEST_DELETE_QUOTE,
  REQUEST_GET_QUOTE,
  REQUEST_GET_QUOTES,
  REQUEST_PERSIST_QUOTE,
  REQUEST_SEND_QUOTE,
  REQUEST_CREATE_NOTE,
  REQUEST_REFUND,
  FAILED_REFUND,
  REQUEST_GET_NOTIFICAITON_TEMPLATE,
  FAILED_GET_NOTIFICAITON_TEMPLATE,
} from 'redux/actions/quote'

import { NOTIFY } from 'redux/actions/notification'

import { QUOTE_QUERY, QUOTES_QUERY } from 'graphql/queries/quote'
import { CREATE_QUOTE_MUTATION, QUOTE_MUTATION } from 'graphql/mutations/quote'

function* createQuote(action) {
  try {
    const { createQuote } = yield call(graphql.mutate, CREATE_QUOTE_MUTATION, {
      bookingId: action.bookingId,
    })

    yield put({ type: RECEIVE_CREATE_QUOTE, id: createQuote.id })

    if (action.callback) {
      yield call(action.callback)
    }

    const notification = successMessage(`Quote created 🔥`)
    yield put({ type: NOTIFY, notification })
  } catch (error) {
    yield put({ type: FAILED_CREATE_QUOTE, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* getQuote(action) {
  try {
    const data = yield call(graphql.query, QUOTE_QUERY, { id: action.id })

    yield put({ type: RECEIVE_GET_QUOTE, data: data.quote })
  } catch (error) {
    yield put({ type: FAILED_GET_QUOTE, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* getQuotes(action) {
  try {
    const data = yield call(graphql.query, QUOTES_QUERY, {
      pageNumber: action.pageNumber,
      pageSize: action.pageSize,
      ...(action.filters ? { filters: action.filters } : {}),
      ...(action.sorting ? { sorting: action.sorting } : {}),
    })

    yield put({
      type: RECEIVE_GET_QUOTES,
      data: data.quotes.data,
      pageNumber: action.pageNumber,
      totalCount: data.quotes.total,
    })
  } catch (error) {
    yield put({ type: FAILED_GET_QUOTES, error })
  }
}

function* persistQuote(action) {
  try {
    delete action.data.__typename

    const sanitisedData = JSON.parse(JSON.stringify(action.data))
    delete sanitisedData.booking.notes

    // Remove ids from new line items
    if (sanitisedData?.lineItems?.length > 0) {
      sanitisedData.lineItems = sanitisedData.lineItems.map(({ id, ...lineItem }) => ({
        ...(id.startsWith('new-') ? {} : { id }),
        ...lineItem,
      }))
    }

    const data = yield call(graphql.mutate, QUOTE_MUTATION, sanitisedData)

    yield put({
      type: RECEIVE_PERSIST_QUOTE,
      data: data.quote,
    })

    const notification = successMessage(savedMessage('quote'))
    yield put({ type: NOTIFY, notification })
  } catch (error) {
    yield put({ type: FAILED_PERSIST_QUOTE, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* deleteQuote(action) {
  try {
    yield call(api.delete, `/quote/${action.id}`)

    const notification = warningMessage(deletedMessage('quote'))
    yield put({ type: NOTIFY, notification })
  } catch (error) {
    yield put({ type: FAILED_DELETE_QUOTE, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* sendQuote(action) {
  try {
    const payload = action.data
    yield call(api.post, `/quote/${action.id}/send`, payload)

    yield put({ type: RECEIVE_SEND_QUOTE })
    yield put({ type: REQUEST_GET_QUOTE, id: action.id })

    const notification = successMessage(`Quote sent 🔥`)
    yield put({ type: NOTIFY, notification })
  } catch (error) {
    yield put({ type: FAILED_SEND_QUOTE, error })

    const notification = errorMessage(`Quote could not be sent 😱`)
    yield put({ type: NOTIFY, notification })
  }
}

function* createNote(action) {
  try {
    const { id, userId, content, callback } = action.data

    yield call(api.post, `/booking/${id}/note`, {
      userId,
      content,
    })

    const notification = successMessage(`Note added 🔥`)
    yield put({ type: NOTIFY, notification })

    if ('function' === typeof callback) {
      yield call(callback)
    }
  } catch (error) {
    yield put({ type: FAILED_SEND_QUOTE, error })

    const notification = errorMessage(`Could not create a note 😱`)
    yield put({ type: NOTIFY, notification })
  }
}

function* requestRefund(action) {
  try {
    const { amountInCents, reason } = action.data

    yield call(api.post, `/payment/${action.id}/refund`, {
      amountInCents,
      reason,
    })

    const notification = successMessage(`Refund requested 🔥`)
    yield put({ type: NOTIFY, notification })
  } catch (error) {
    yield put({ type: FAILED_REFUND, error })

    const notification = errorMessage(`Could not create a refund 😱`)
    yield put({ type: NOTIFY, notification })
  }
}

function* getNotificationTemplate(action) {
  try {
    const { id } = action
    const response = yield call(api.get, `/quote/${id}/notificationTemplate`)

    yield put({ type: RECEIVE_GET_NOTIFICATION_TEMPLATE, data: response })
  } catch (error) {
    yield put({ type: FAILED_GET_NOTIFICAITON_TEMPLATE, error })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_CREATE_NOTE, createNote)
  yield takeLatest(REQUEST_CREATE_QUOTE, createQuote)
  yield takeLatest(REQUEST_DELETE_QUOTE, deleteQuote)
  yield takeLatest(REQUEST_GET_NOTIFICAITON_TEMPLATE, getNotificationTemplate)
  yield takeLatest(REQUEST_GET_QUOTE, getQuote)
  yield takeLatest(REQUEST_GET_QUOTES, getQuotes)
  yield takeLatest(REQUEST_PERSIST_QUOTE, persistQuote)
  yield takeLatest(REQUEST_SEND_QUOTE, sendQuote)
  yield takeLatest(REQUEST_REFUND, requestRefund)
}
