export const REGION_MUTATION = `
mutation(
  $id: ID
  $name: String!
) {
  region(
    id: $id
    name: $name
  ) {
    id
    name
    # user {
    #   id
    # }
  }
}
`
