export const FAILED_DELETE_CUSTOMER = 'FAILED_DELETE_CUSTOMER'
export const FAILED_GET_CUSTOMER = 'FAILED_GET_CUSTOMER'
export const FAILED_GET_CUSTOMERS = 'FAILED_GET_CUSTOMERS'
export const FAILED_PERSIST_CUSTOMER = 'FAILED_PERSIST_CUSTOMER'
export const RECEIVE_DELETE_CUSTOMER = 'RECEIVE_DELETE_CUSTOMER'
export const RECEIVE_GET_CUSTOMER = 'RECEIVE_GET_CUSTOMER'
export const RECEIVE_GET_CUSTOMERS = 'RECEIVE_GET_CUSTOMERS'
export const RECEIVE_PERSIST_CUSTOMER = 'RECEIVE_PERSIST_CUSTOMER'
export const REQUEST_DELETE_CUSTOMER = 'REQUEST_DELETE_CUSTOMER'
export const REQUEST_GET_CUSTOMER = 'REQUEST_GET_CUSTOMER'
export const REQUEST_GET_CUSTOMERS = 'REQUEST_GET_CUSTOMERS'
export const REQUEST_PERSIST_CUSTOMER = 'REQUEST_PERSIST_CUSTOMER'
