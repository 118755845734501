import {call, takeLatest, put} from 'redux-saga/effects'
import graphql from 'services/graphql'
import {
  errorMessageFromGraphQL,
  successMessage,
  warningMessage,
  deletedMessage,
  savedMessage
} from 'config/toast'

import {
  FAILED_GET_ROLE,
  FAILED_GET_ROLES,
  FAILED_PERSIST_ROLE,
  FAILED_DELETE_ROLE,
  RECEIVE_GET_ROLE,
  RECEIVE_GET_ROLES,
  RECEIVE_PERSIST_ROLE,
  RECEIVE_DELETE_ROLE,
  REQUEST_GET_ROLE,
  REQUEST_GET_ROLES,
  REQUEST_PERSIST_ROLE,
  REQUEST_DELETE_ROLE,
} from 'redux/actions/role'

import { NOTIFY } from 'redux/actions/notification'

import {ROLE_QUERY, ROLES_QUERY} from 'graphql/queries/role'
import {ROLE_MUTATION} from 'graphql/mutations/role'

function* getRole(action) {
  try {
    const data = yield call(graphql.query, ROLE_QUERY, { id: action.id })

    yield put({ type: RECEIVE_GET_ROLE, data: data.roles[0] })
  } catch(error) {
    yield put({ type: FAILED_GET_ROLE, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* getRoles(action) {
  try {
    const data = yield call(graphql.query, ROLES_QUERY, {
      // pageNumber: action.pageNumber,
      // pageSize: action.pageSize,
      // ...action.filters,
      // ...(action.sorting ? { sorting: action.sorting } : {})
    })

    yield put({ type: RECEIVE_GET_ROLES, data: data.roles })
  } catch(error) {
    yield put({ type: FAILED_GET_ROLES, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* persistRole(action) {
  try {
    delete action.data.__typename

    const data = yield call(graphql.mutate, ROLE_MUTATION, action.data)

    yield put({
      type: RECEIVE_PERSIST_ROLE,
      data: data.role,
    })

    const notification = successMessage(savedMessage('role'))
    yield put({ type: NOTIFY, notification })
  } catch(error) {
    yield put({ type: FAILED_PERSIST_ROLE, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

function* deleteRole(action) {
  try {
    delete action.data.__typename

    const data = yield call(graphql.mutate, DELETE_ROLE_MUTATION, { id: action.data.id } )

    yield put({
      type: RECEIVE_DELETE_ROLE,
      data: data.deleteRole,
    })

    const notification = warningMessage(deletedMessage('role'))
    yield put({ type: NOTIFY, notification })
  } catch(error) {
    yield put({ type: FAILED_DELETE_ROLE, error })

    const notification = yield errorMessageFromGraphQL(error)
    yield put({ type: NOTIFY, notification })
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_ROLE, getRole)
  yield takeLatest(REQUEST_GET_ROLES, getRoles)
  yield takeLatest(REQUEST_PERSIST_ROLE, persistRole)
  yield takeLatest(REQUEST_DELETE_ROLE, deleteRole)
}
