import 'moment/locale/nl.js'
import moment from 'moment'

export const host = typeof window !== 'undefined' ? window.location.hostname : null

export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

export function capitalise(string = '') {
  return string && string[0].toUpperCase() + string.slice(1)
}

export function centsToEuros(cents) {
  const amount = cents / 100

  return Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount)
}

export function toEuros(amount) {
  return Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount)
}

export function toFloat(input) {
  if ('string' === typeof input) {
    return parseFloat(input?.replace(',', '.'))
  }

  if ('number' === typeof input) {
    return parseFloat(input)
  }
}

export function join(delimiter = ' ', ...args) {
  return args.join(delimiter).trim()
}

export function sluggify(string = '') {
  return (
    string &&
    [...string.toLowerCase()]
      .filter((char) => /[a-zA-Z\s-]/.test(char))
      .join('')
      .replace(/\s/g, '-')
  )
}

export function toQueryString(params) {
  return Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&')
}

export function validateEmailAddress(email = '') {
  const regex =
    /^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]{1,64}@(?:[a-zA-Z0-9-]{1,63}[.]{1}){1,125}[a-zA-Z0-9]{2,63}$/

  return email && regex.test(email)
}

export function eurosToCents(value) {
  return parseInt(parseFloat(value) * 100, 10)
}

export function minimumPrice({ minGroupSize = 1, pricePerPersonInCents = 0 }) {
  if ('string' === typeof pricePerPersonInCents) {
    const asNumber = parseFloat(pricePerPersonInCents?.replace(',', '.'))

    return minGroupSize * asNumber
  }

  if ('number' === typeof pricePerPersonInCents) {
    return minGroupSize * pricePerPersonInCents
  }

  return 0
}

export function toLocalDate(i18n, date) {
  moment.locale(i18n.language)

  return moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss')
}
