import App from 'common/ui/App'
import { hydrateRoot } from 'react-dom/client'
import React from 'react'
import { Router } from 'react-router-dom'
import { loadableReady } from '@loadable/component'
import { Provider } from 'react-redux'
// Import configs
import { BugsnagErrorBoundary } from 'common/config/bugsnag'
import store from 'common/config/store'
import history from 'common/config/history'
import { initGA } from 'common/config/ga'

// Initiate Google Analytics
initGA()

function render(Root) {
  loadableReady().then(() => {
    setTimeout(() => {
      hydrateRoot(
        document.getElementById('root'),
        <BugsnagErrorBoundary>
          <Provider store={store}>
            <Router history={history}>
              <Root />
            </Router>
          </Provider>
        </BugsnagErrorBoundary>
      )

      // Eliminate flash
      document.getElementById('root').style.display = 'block'
    }, 250)
  })
}

if (module.hot) {
  module.hot.accept('common/ui/App', () => {
    const NewApp = require('common/ui/App').default

    return render(NewApp)
  })
}

render(App)
